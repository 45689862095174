import { Action } from '@ngrx/store';
import { User } from '../models/user.model';

export const START_LOADING = '[UI] Start Loading';
export const STOP_LOADING = '[UI] Stop Loading';
export const SIDENAV_SHOW = '[UI] Show sidenav';
export const SIDENAV_HIDE = '[UI] Hide sidenav';
export const HEADER_SHOW = '[UI] Show header';
export const HEADER_HIDE = '[UI] Hide header';
export const DEBUG_SHOW = '[UI] Show debug component';
export const DEBUG_HIDE = '[UI] Hide debug component';
export const USER_ADD = '[UI] Add user details';
export const USER_REMOVE = '[UI] Remove user details';
export const MEDIC_ID_ADD = '[UI] Add current medic id';
export const MEDIC_ID_REMOVE = '[UI] Remove current medic id';
export const MEDIC_NUME_ADD = '[UI] Add current medic nume';
export const MEDIC_NUME_REMOVE = '[UI] Remove current medic nume';

export const APP_SECTION_ADD = '[UI] Add active app section';
export const APP_SECTION_NAME_ADD = '[UI] Add active app section name';
export const APP_SUBSECTION_ADD = '[UI] Add active app subSection name';
export const APP_SUBSECTION_REMOVE = '[UI] Remove active app section name';
export const APP_SUBSECTION_STATE_ADD = '[UI] Add app subsection state';
export const APP_SUBSECTION_STATE_REMOVE = '[UI] Remove app subsection state';

export class StartLoading implements Action {
    readonly type = START_LOADING;
}

export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}

export class SidenavShow implements Action {
    readonly type = SIDENAV_SHOW;
}

export class SidenavHide implements Action {
    readonly type = SIDENAV_HIDE;
}

export class DebugShow implements Action {
    readonly type = DEBUG_SHOW;
}

export class DebugHide implements Action {
    readonly type = DEBUG_HIDE;
}

export class UserAdd implements Action {
    readonly type = USER_ADD;

    constructor(public payload: User) { }
}

export class UserRemove implements Action {
    readonly type = USER_REMOVE;
}

export class MedicIdAdd implements Action {
    readonly type = MEDIC_ID_ADD;

    constructor(public payload: string) { }
}

export class MedicIdRemove implements Action {
    readonly type = MEDIC_ID_REMOVE;
}

export class MedicNumeAdd implements Action {
    readonly type = MEDIC_NUME_ADD;

    constructor(public payload: string) { }
}

export class MedicNumeRemove implements Action {
    readonly type = MEDIC_NUME_REMOVE;
}
export class AppSectionAdd implements Action {
    readonly type = APP_SECTION_ADD;

    constructor(public payload: string) { }
}

export class AppSectionNameAdd implements Action {
    readonly type = APP_SECTION_NAME_ADD;

    constructor(public payload: string) { }
}

export class AppSubsectionAdd implements Action {
    readonly type = APP_SUBSECTION_ADD;

    constructor(public payload: string) { }
}

export class AppSubsectionRemove implements Action {
    readonly type = APP_SUBSECTION_REMOVE;
}

export class AppSubsectionStateAdd implements Action {
    readonly type = APP_SUBSECTION_STATE_ADD;

    constructor(public payload: string) { }
}

export class AppSubsectionStateRemove implements Action {
    readonly type = APP_SUBSECTION_STATE_REMOVE;
}

export type UIActions = StartLoading | StopLoading |
    SidenavShow | SidenavHide | DebugShow | DebugHide |
    UserAdd | UserRemove | MedicIdAdd | MedicIdRemove | MedicNumeAdd | MedicNumeRemove|
    AppSectionAdd | AppSectionNameAdd | AppSubsectionAdd | AppSubsectionRemove |
    AppSubsectionStateAdd | AppSubsectionStateRemove;
