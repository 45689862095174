

import { Transfer } from '../models/medic-actiuni.model';
import { Notificari } from '../models/nav-notificari.model';
import { DocCertProfCurent } from '../models/medic-doc.model';

import {
  AlertActions,
  TRANSFER_ADD,
  TRANSFER_DEL,
  CPC_ADD,
  CPC_DEL,
  NOTIFICARI_ADD
} from './alert.actions';

export interface State {
  transfer: Transfer[];
  cpc: DocCertProfCurent[];
  notificari: Notificari[];
}

const initialState: State = {
  transfer: [],
  cpc: [],
  notificari: []
};

export function alertReducer(state = initialState, action: AlertActions) {
  switch (action.type) {
    case TRANSFER_ADD:
      return {
        ...state,
        transfer: action.payload
      };
    case TRANSFER_DEL:
      return {
        ...state,
        transfer: null
      };
    case CPC_ADD:
      return {
        ...state,
        cpc: action.payload
      };
    case CPC_DEL:
      return {
        ...state,
        cpc: null
      };
    case NOTIFICARI_ADD:
      return {
        ...state,
        notificari: action.payload
      };
    default:
      return state;
  }
}

export const getAlertState = (state: State) => state;
export const getAlertTransfer = (state: State) => state.transfer;
export const getAlertCpc = (state: State) => state.cpc;
export const getNotificari = (state: State) => state.notificari;
