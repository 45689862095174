import { APP_INITIALIZER, NgModule, ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { registerLocaleData } from '@angular/common';
import localeRo from '@angular/common/locales/ro';
import localeRoExtra from '@angular/common/locales/ro';
registerLocaleData(localeRo, 'ro', localeRoExtra);

import { AppRoutingModule } from './app-routing.module';

import { StoreModule } from '@ngrx/store';
import { reducers } from './app.reducer';

import { JWTInterceptor } from './shared/interceptors/jwt.interceptor';

import { AuthService } from './shared/services/auth.service';
import { RegistreService } from './shared/services/registre.service';
import { CautareService } from './shared/services/cautare.service';
import { AlertsService } from './shared/services/alerts.service';

import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './autorizare/login/login.component';
import { Error404Component } from './core/error404/error404.component';
import { CautareComponent } from './dashboard/cautare/cautare.component';
import { RezultatCmjComponent } from './dashboard/rezultat-cmj/rezultat-cmj.component';
import { RezultatCmrComponent } from './dashboard/rezultat-cmr/rezultat-cmr.component';

import { SharedModule } from './shared/modules/shared.module';

import { StoreDevtools, StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ActiuniService } from './shared/services/actiuni.service';
import { NavigationModule } from './navigation/navigation.module';
import { environment } from '../environments/environment';
import { DialogComponent } from './core/dialog/dialog.component';
import { DialogDynamicComponent } from './core/dialog/dialog-dynamic/dialog-dynamic.component';
import { DialogInvalidareComponent } from './core/dialog-invalidare/dialog-invalidare.component';
import { FormAsiguratorComponent } from './shared/components/form-asigurator/form-asigurator.component';

Sentry.init({
    dsn: 'https://c1d9c52cd8f243668fe82e2b79350dd0@o769226.ingest.sentry.io/6013490',
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: ['localhost', 'https://rm.cmr.ro', 'https://rm.cmr.comprami.ro'],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    handleError(error) {
        Sentry.captureException(error.originalError || error);
    }
}

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        LoginComponent,
        Error404Component,
        CautareComponent,
        RezultatCmjComponent,
        RezultatCmrComponent,
        DialogComponent,
        DialogDynamicComponent,
        DialogInvalidareComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        AppRoutingModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        !environment.production ? StoreDevtoolsModule.instrument({
            maxAge: 150
        }) : [],
        NavigationModule
    ],
    providers: [
        AuthService,
        RegistreService,
        CautareService,
        AlertsService,
        ActiuniService,
        // { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true },
        {
            provide: ErrorHandler, useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
