import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ApiService } from './api.service';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as UI from '../redux/ui.actions';
import * as Alert from '../redux/alert.actions';

import { SnackBarService } from './snackbar.service';
import { Transfer } from '../models/medic-actiuni.model';
import { Router } from '@angular/router';
import { tap, shareReplay } from 'rxjs/operators';
import { DocCertProfCurent } from '../models/medic-doc.model';

@Injectable()
export class AlertsService {

  constructor(
    private store: Store<fromRoot.State>,
    private apiService: ApiService,
    private snackBar: SnackBarService,
    private router: Router,
  ) { }

  getAlert(endpoint) {
    this.store.dispatch(new UI.StartLoading());
    const answer = this.apiService.getTransfer(endpoint).pipe(
      tap(
        () => this.store.dispatch(new UI.StopLoading())
      ),
      shareReplay()
    )
      .subscribe(
        (response) => {
          switch (endpoint) {
            case 'transfer':
              this.store.dispatch(new Alert.TransferAdd(response as Transfer[]));
              break;

            case 'cpc':
              this.store.dispatch(new Alert.CpcAdd(response as DocCertProfCurent[]));
              break;

            default:
              break;
          }
          // this.router.navigateByUrl('/');
        },
        (error: HttpErrorResponse) => {
          this.snackBar.showError(error);
        }
      );
  }

  getCpcList(endpoint, full) {
    this.store.dispatch(new UI.StartLoading());
    const answer = this.apiService.getCpc(endpoint, full).pipe(
      tap(
        () => this.store.dispatch(new UI.StopLoading())
      ),
      shareReplay()
    )
      .subscribe(
        (response) => {
          this.store.dispatch(new Alert.CpcAdd(response as DocCertProfCurent[]));
        },
          // this.router.navigateByUrl('/');
        (error: HttpErrorResponse) => {
          this.snackBar.showError(error);
        }
      );
  }
}
