
import {
  UIActions,
  START_LOADING,
  STOP_LOADING,
  SIDENAV_SHOW,
  SIDENAV_HIDE,
  USER_ADD,
  USER_REMOVE,
  DEBUG_SHOW,
  DEBUG_HIDE,
  APP_SUBSECTION_STATE_ADD,
  MEDIC_ID_ADD,
  MEDIC_ID_REMOVE,
  APP_SECTION_ADD,
  APP_SECTION_NAME_ADD,
  APP_SUBSECTION_ADD,
  APP_SUBSECTION_REMOVE
} from './ui.actions';
import { User } from '../models/user.model';
import { AppSubsectionRemove, MEDIC_NUME_ADD, MEDIC_NUME_REMOVE } from './ui.actions';

export interface State {
  isLoading: boolean;
  sidenavShow: boolean;
  debugShow: boolean;
  user: User;
  appSection: string;
  appSectionName: string;
  appSubsection: string;
  appSubsectionState: string;
  currentMedicId: string;
  currentMedicNume: string;
  isMedicSelected: boolean;
}

const initialState: State = {
  isLoading: false,
  sidenavShow: false,
  debugShow: false,
  user: null,
  appSection: 'dashboard',
  appSectionName: 'Pagina Principală',
  appSubsection: null,
  appSubsectionState: 'view',
  currentMedicId: null,
  currentMedicNume: null,
  isMedicSelected: false
};

export function uiReducer(state = initialState, action: UIActions) {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case SIDENAV_SHOW:
      return {
        ...state,
        sidenavShow: true
      };
    case SIDENAV_HIDE:
      return {
        ...state,
        sidenavShow: false
      }; case DEBUG_SHOW:
      return {
        ...state,
        debugShow: true
      };
    case DEBUG_HIDE:
      return {
        ...state,
        debugShow: false
      };
    case USER_ADD:
      return {
        ...state,
        user: action.payload
      };
    case USER_REMOVE:
      return {
        ...state,
        user: null
      };
    case MEDIC_ID_ADD:
      return {
        ...state,
        currentMedicId: action.payload,
        isMedicSelected: true
      };
    case MEDIC_ID_REMOVE:
      return {
        ...state,
        currentMedicId: null,
        isMedicSelected: false
      };
    case MEDIC_NUME_ADD:
      return {
        ...state,
        currentMedicNume: action.payload
      };
    case MEDIC_NUME_REMOVE:
      return {
        ...state,
        currentMedicNume: null
      };
    case APP_SECTION_ADD:
      return {
        ...state,
        appSection: action.payload
      };
    case APP_SECTION_NAME_ADD:
      return {
        ...state,
        appSectionName: action.payload
      };
    case APP_SUBSECTION_ADD:
      return {
        ...state,
        appSubsection: action.payload
      };
    case APP_SUBSECTION_REMOVE:
      return {
        ...state,
        appSubsection: null
      };
    case APP_SUBSECTION_STATE_ADD:
      return {
        ...state,
        appSubsectionState: action.payload
      };
    case APP_SUBSECTION_REMOVE:
      return {
        ...state,
        AppSubsectionState: null
      };
    default:
      return state;
  }
}

export const getIsLoading = (state: State) => state.isLoading;
export const getSidenav = (state: State) => state.sidenavShow;
export const getDebug = (state: State) => state.debugShow;
export const getUser = (state: State) => state.user;
export const getMedicId = (state: State) => state.currentMedicId;
export const getMedicSelected = (state: State) => state.isMedicSelected;
export const getMedicNume = (state: State) => state.currentMedicNume;
export const getAppSection = (state: State) => state.appSection;
export const getAppSectionName = (state: State) => state.appSectionName;
export const getAppSubsection = (state: State) => state.appSubsection;
export const getAppSubsectionState = (state: State) => state.appSubsectionState;
