<div [className]="prod ? 'app-login-container' : 'app-login-container-devel'" fxLayout="column" fxLayoutAlign="center center">
  <div class="app-login-main mat-elevation-z12">
    <mat-card>
      <mat-card-title>
        <span *ngIf="!prod">Site Test - C.M.R.</span>
        <span *ngIf="prod">Colegiul Medicilor din România</span>
      </mat-card-title>
      <mat-card-content>
        <form [formGroup]="userForm" (ngSubmit)="onLogin()" (keyup.enter)="onLogin()" fxLayout="column" fxLayoutGap="10px">

          <mat-form-field class="display-block">
            <input autofocus matInput formControlName="email" type="text" autocomplete="username" placeholder="email"
              required>
            <mat-error>Adresa de email este invalidă</mat-error>
          </mat-form-field>

          <mat-form-field class="display-block">
            <input matInput formControlName="password" type="{{type}}" autocomplete="current-password" placeholder="password"
              required #inputFocus>
            <button type="button" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="toggleShow()">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <mat-error>Parola este invalidă</mat-error>
          </mat-form-field>

        </form>
      </mat-card-content>

      <mat-card-actions fxLayoutAlign="center">
        <button [disabled]="userForm.invalid || isLoading" type="submit" mat-button color="primary" (click)="onLogin()">Autentificare</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>