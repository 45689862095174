import { Action } from '@ngrx/store';

export const SET_AUTH = '[Auth] Set authenticated';
export const SET_NOT_AUTH = '[Auth] Remove authenticated';
export const SET_ADMIN = '[Auth] Set Admin to true';
export const SET_ACCESS_TOKEN = '[Auth] Set access token';
export const REMOVE_ACCESS_TOKEN = '[Auth] Remove access token';
export const SET_REFRESH_TOKEN = '[Auth] Set refresh token';

export class SetAuth implements Action {
    readonly type = SET_AUTH;
}

export class SetNotAuth implements Action {
    readonly type = SET_NOT_AUTH;
}

export class SetAdmin implements Action {
    readonly type = SET_ADMIN;
}

export class SetAccessToken implements Action {
    readonly type = SET_ACCESS_TOKEN;

    constructor(public payload: string) { }
}

export class RemoveAccessToken implements Action {
    readonly type = REMOVE_ACCESS_TOKEN;
}
export class SetRefreshToken implements Action {
    readonly type = SET_REFRESH_TOKEN;

    constructor(public payload: string) { }
}

export type AuthActions = SetAuth | SetNotAuth | SetAdmin | SetAccessToken | SetRefreshToken | RemoveAccessToken;
