import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as UI from '../../shared/redux/ui.actions';
import * as Med from '../../shared/redux/medic.actions';

import { MedicRegistru } from '../../shared/models/cautare.model';
import { CautareService } from '../../shared/services/cautare.service';
import { RegistreService } from '../../shared/services/registre.service';
import { User } from '../../shared/models/user.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-rezultat-cmj',
    templateUrl: './rezultat-cmj.component.html',
    styleUrls: ['./rezultat-cmj.component.scss']
})
export class RezultatCmjComponent implements OnInit, AfterViewInit {

    prod = environment.production;
    taxe = environment.taxeCmj;
    emc = environment.emcCmj;

    currentUser: User;
    currentUser$: Subscription;

    displayedColumns = [
        'status_icon',
        'status',
        'nume',
        'prenume',
        'nume_anterior',
        'cuim',
        'cnp',
        'modificare',
        'documente',
        // 'certificat',
        // 'avizare',
        'actiuni'
    ];

    dataSource = new MatTableDataSource<MedicRegistru>();

    selectedRowIndex = -1;

    pagini: number;
    currentPage: number;
    nextPage: string;
    prevPage: string;
    total: number;

    @ViewChild(MatSort) sort: MatSort;
    constructor(
        private router: Router,
        private cautareMedic: CautareService,
        private store: Store<fromRoot.State>,
        public regServ: RegistreService
    ) {
        this.currentUser$ = this.store.select(fromRoot.getUserDetails).subscribe(
            user => {
                this.currentUser = user;
            }
        );
    }

    ngOnInit() {
        if (this.taxe.includes(this.currentUser.judet_id)) {
            this.displayedColumns.push('taxe');
        }
        this.store.select(fromRoot.getCautareMedicCMJ).subscribe(lista => {
            if (lista != null) {
                this.dataSource.data = lista.results;
                this.total = lista.total;
                this.pagini = lista.pages;
                this.nextPage = lista.next;
                this.prevPage = lista.prev;

                const prevPg = this.prevPage.match(/=\s*(.*)$/);
                const nextPg = this.nextPage.match(/=\s*(.*)$/);

                if (+nextPg[1] === 2) {
                    this.currentPage = 1;
                } else {
                    this.currentPage = +prevPg[1] + 1;
                }
            }
        });
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    doFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
    }

    medicSelect(endpoint, medicId) {
        const pageState = 'view';
        const page = {
            section: 'medic',
            subSection: endpoint,
            data: null,
            state: pageState
        };
        this.store.dispatch(new UI.MedicIdAdd(medicId));
        this.store.dispatch(new UI.AppSectionAdd('Medic'));
        this.store.dispatch(new UI.AppSubsectionAdd(endpoint));
        this.store.dispatch(new UI.AppSubsectionStateAdd(null));
        this.store.dispatch(new Med.SetMedicAvizDraft(null));
        this.store.dispatch(new Med.SetMedicCertDraft(null));
        this.store.dispatch(new Med.UnsetMedicData());
        this.router.navigate(['/medic']);
    }

    clickRow(row) {
        this.selectedRowIndex = row.id;
    }

    getNewPage(searchVal, searchType) {
        this.cautareMedic.cautaMedic(searchVal, searchType);
    }

    getColor(statusId) {

        if ([1, 2, 3].includes(statusId)) {
            return 'primary';
        }

        return 'warn';
    }
}
