import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as UI from '../../../shared/redux/ui.actions';
import * as Alert from '../../../shared/redux/alert.actions';

import { Notificari } from '../../../shared/models/nav-notificari.model';

import { ApiService } from '../../../shared/services/api.service';
import { SnackBarService } from '../../../shared/services/snackbar.service';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class NavNotificariService {

    notificari: Notificari[];
    endpoint = 'user/notificari';

    constructor(
        private http: HttpClient,
        private apiServ: ApiService,
        private snackBar: SnackBarService,
        private store: Store<fromRoot.State>
    ) {
        this.getNotificari();
    }


    getNotificari() {
        const answer = this.apiServ.getItem(this.endpoint)
            .pipe(
                shareReplay()
            )
            .subscribe(
                (response) => {
                    this.store.dispatch(new Alert.NotificariAdd(response));
                },
                (error: HttpErrorResponse) => {
                    if (error.status !== 404) {
                        this.snackBar.showError(error);
                    } else {
                        this.snackBar.showSnackbar("Nu exista notificari.");
                    }
                }
            );
    }

    delNotificari(item) {
        // const params = { mid: item.mid };
        const answer = this.apiServ.delItem(NaN,`${this.endpoint}/${item.mid}`)
            .pipe(
                shareReplay()
            )
            .subscribe(
                (response) => {
                    this.snackBar.showSnackbar("Notificarea a fost stearsa");
                    this.getNotificari();
                },
                (error: HttpErrorResponse) => {
                    this.snackBar.showError(error);
                }
            );
    }
}
