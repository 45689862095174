import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromUI from './shared/redux/ui.reducer';
import * as fromAuth from './shared/redux/auth.reducer';
import * as fromRegistre from './shared/redux/registre.reducer';
import * as fromCautareMedic from './shared/redux/cautare.reducer';
import * as fromMedic from './shared/redux/medic.reducer';
import * as fromAlert from './shared/redux/alert.reducer';
import * as fromAdmin from './shared/redux/admin.reducer';

export interface State {
    ui: fromUI.State;
    auth: fromAuth.State;
    registre: fromRegistre.State;
    cautareMedic: fromCautareMedic.State;
    medic: fromMedic.State;
    alert: fromAlert.State;
    admin: fromAdmin.State;
}

export const reducers: ActionReducerMap<State> = {
    ui: fromUI.uiReducer,
    auth: fromAuth.authReducer,
    registre: fromRegistre.registreReducer,
    cautareMedic: fromCautareMedic.cautareReducer,
    medic: fromMedic.medicReducer,
    alert: fromAlert.alertReducer,
    admin: fromAdmin.adminReducer
};

export const getUiState = createFeatureSelector<fromUI.State>('ui');
export const getIsLoading = createSelector(getUiState, fromUI.getIsLoading);
export const getSidenavShow = createSelector(getUiState, fromUI.getSidenav);
export const getDebugShow = createSelector(getUiState, fromUI.getDebug);
export const getUserDetails = createSelector(getUiState, fromUI.getUser);
export const getCurrentMedicId = createSelector(getUiState, fromUI.getMedicId);
export const getIsMedicSelected = createSelector(getUiState, fromUI.getMedicSelected);
export const getCurrentMedicNume = createSelector(getUiState, fromUI.getMedicNume);
export const appSection = createSelector(getUiState, fromUI.getAppSection);
export const appSectionName = createSelector(getUiState, fromUI.getAppSectionName);
export const appSubsection = createSelector(getUiState, fromUI.getAppSubsection);
export const appSubsectionState = createSelector(getUiState, fromUI.getAppSubsectionState);


export const getAuthState = createFeatureSelector<fromAuth.State>('auth');
export const getIsAuthenticated = createSelector(getAuthState, fromAuth.getIsAuthenticated);
export const getIsAdministrator = createSelector(getAuthState, fromAuth.getIsAdmin);
export const getTokenAccess = createSelector(getAuthState, fromAuth.tokenAccess);
export const getTokenRefresh = createSelector(getAuthState, fromAuth.tokenRefresh);

export const getRegState = createFeatureSelector<fromRegistre.State>('registre');
export const getRegistre = createSelector(getRegState, fromRegistre.Registre);
export const getRegJudet = createSelector(getRegState, fromRegistre.RegJudet);
export const getRegMedicStatus = createSelector(getRegState, fromRegistre.RegMedicStatus);
export const getRegTara = createSelector(getRegState, fromRegistre.RegTara);
export const getRegActIdTip = createSelector(getRegState, fromRegistre.RegActIdTip);
export const getRegFacultate = createSelector(getRegState, fromRegistre.RegFacultate);
export const getRegFacDocTip = createSelector(getRegState, fromRegistre.RegFacDocTip);
export const getRegPpuDocTip = createSelector(getRegState, fromRegistre.RegPpuDoc);
export const getRegPpu = createSelector(getRegState, fromRegistre.RegPpu);
export const getRegPpuGrad = createSelector(getRegState, fromRegistre.RegPpuGrad);
export const getRegPpuTip = createSelector(getRegState, fromRegistre.RegPpuTip);
export const getRegTitlu = createSelector(getRegState, fromRegistre.RegTitlu);
export const getRegAdresaTip = createSelector(getRegState, fromRegistre.RegAdresaTip);
export const getRegDocStatus = createSelector(getRegState, fromRegistre.RegDocStatus);
export const getRegDocTip = createSelector(getRegState, fromRegistre.RegDocTip);
export const getRegCmj = createSelector(getRegState, fromRegistre.RegCmj);
export const getRegMedicActiuniTip = createSelector(getRegState, fromRegistre.RegMedicActiuniTip);
export const getRegCmjServiciiTip = createSelector(getRegState, fromRegistre.RegCmjServiciiTip);
export const getRegEmcTip = createSelector(getRegState, fromRegistre.RegEmcTip);

export const getCautareMedicState = createFeatureSelector<fromCautareMedic.State>('cautareMedic');
export const getCautareMedicCMJ = createSelector(getCautareMedicState, fromCautareMedic.getRezCautareCMJ);
export const getCautareMedicCMR = createSelector(getCautareMedicState, fromCautareMedic.getRezCautareCMR);

export const getMedicState = createFeatureSelector<fromMedic.State>('medic');
export const medicDate = createSelector(getMedicState, fromMedic.MedicData);
export const medicCertDraft = createSelector(getMedicState, fromMedic.MedicCertDraftData);
export const medicAvizDraft = createSelector(getMedicState, fromMedic.MedicAvizDraftData);
export const medicCertProfCurentDraft = createSelector(getMedicState, fromMedic.MedicCertProfCurentData);
export const medicTransfer = createSelector(getMedicState, fromMedic.MedicTransfer);
export const medicEmcTotal = createSelector(getMedicState, fromMedic.MedicEmcTotalData);

export const getAlertState = createFeatureSelector<fromAlert.State>('alert');
export const transferDate = createSelector(getAlertState, fromAlert.getAlertTransfer);
export const cpcDate = createSelector(getAlertState, fromAlert.getAlertCpc);
export const alertDate = createSelector(getAlertState, fromAlert.getAlertState);
export const notificariDate = createSelector(getAlertState, fromAlert.getNotificari);

export const getAdminState = createFeatureSelector<fromAdmin.State>('admin');
export const userList = createSelector(getAdminState, fromAdmin.UsersList);


