import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Store } from '@ngrx/store';

import * as fromRoot from '../../app.reducer';
import * as UI from '../redux/ui.actions';
import * as CautareMedic from '../redux/cautare.actions';

import { ApiService } from './api.service';
import { SnackBarService } from './snackbar.service';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable()
export class CautareService {
  constructor(
    private store: Store<fromRoot.State>,
    private apiService: ApiService,
    private snackBar: SnackBarService
  ) {}

  private showError(error: HttpErrorResponse) {
    this.store.dispatch(new UI.StopLoading());

    if (error.error instanceof ErrorEvent) {
      this.snackBar.showSnackbar('Eroare Client: ' + error.error.message, 'snackbar-alert');
    } else {
      if (error.error.msg === undefined) {
        this.snackBar.showSnackbar(`Eroare Server : ${error.status}, ` + error.message, 'snackbar-alert');
      } else {
        this.snackBar.showSnackbar(`Eroare Server : ${error.status}, ` + error.error.msg, 'snackbar-alert');
      }
    }
  }

  cautaMedic(data: string, endpoint: string) {
    const value2 = data.replace(/\s/g, '');
    this.store.dispatch(new UI.StartLoading());
    const answer = this.apiService
      .cautareMedic(value2, endpoint).pipe(
        tap(() => this.store.dispatch(new UI.StopLoading())),
        shareReplay()
      )
      .subscribe(
        (response: any) => {
          switch (endpoint) {
            case 'cmj':
              // this.store.dispatch(new CautareMedic.ListaCMJDelete());
              this.store.dispatch(new CautareMedic.ListaCMJAdd(response));
              break;
            case 'cmr':
              // this.store.dispatch(new CautareMedic.ListaCMRDelete());
              this.store.dispatch(new CautareMedic.ListaCMRAdd(response));
              break;
          }
        },
        (error: HttpErrorResponse) => {
          this.showError(error);
        }
      );
  }
}
