export class NavLeftModel {
  public model: any[];

  constructor() {
    this.model = [
      {
        'id': 'home',
        'title': 'Pagina principală',
        'type': 'item',
        'icon': 'home',
        'url': '/dashboard'
      },
      {
        'id': 'medic',
        'title': 'Medic',
        'type': 'item',
        'icon': 'assignment_ind',
        'url': '/medic'
      },
      {
        'id': 'cmj',
        'title': 'C.M.J',
        'type': 'collapse',
        'icon': 'location_city',
        children: [
          {
            'id': 'cmj-transfer',
            'title': 'Transfer',
            'type': 'item',
            'url': '/cmj/cmj-transfer'
          },
          {
            'id': 'cmj-cpc',
            'title': 'Cert. Prof. Curent',
            'type': 'item',
            'url': '/cmj/cert-prof-curent'
          },
          {
            'id': 'cmj-rapoarte',
            'title': 'Rapoarte',
            'type': 'item',
            'url': '/cmj/rapoarte'
          },
          {
            'id': 'cmj-configurare',
            'title': 'Configurare',
            'type': 'item',
            'url': '/cmj/configurare'
          },
          // {
          //     'id': 'detalii',
          //     'title': 'Detalii',
          //     'type': 'item',
          //     'url': '/cmj/detalii'
          // },
        ]
      }
    ];
  }
}
