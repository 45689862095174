<div class="toolbar-user-container">
    <button mat-button (click)="toggleDropDown()" class="toolbar-user-btn" [class.open]="isOpen">
        <span fxLayout="row" fxLayoutAlign="start center">
            <img class="avatar" [src]="'assets/images/avatars/noavatar.png'">
            <span class="name" fxHide fxShow.gt-sm>{{currentUser.nume}}</span>
            <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
        </span>
    </button>

    <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
        <div class="content">
            <div class="list">
                <div class="item" fxLayout="row" fxLayoutAlign="start center" mat-ripple (click)="refresh()">
                    <mat-icon class="icon">refresh</mat-icon>
                    <span class="title">Registre</span>
                </div>
                <mat-divider></mat-divider>
                <div class="item" fxLayout="row" fxLayoutAlign="start center" mat-ripple (click)="get_anyDesk()">
                    <mat-icon class="icon">get_app</mat-icon>
                    <span class="title">AnyDesk</span>
                </div>
                <mat-divider></mat-divider>
                <div class="item" fxLayout="row" fxLayoutAlign="start center" mat-ripple (click)="showVersion()">
                    <mat-icon class="icon">info</mat-icon>
                    <span class="title">Versiune App</span>
                </div>
                <mat-divider></mat-divider>
                <div class="item" fxLayout="row" fxLayoutAlign="start center" routerLink="/autorizare" mat-ripple>
                    <mat-icon class="icon">exit_to_app</mat-icon>
                    <span class="title">Logout</span>
                </div>
            </div>
        </div>
    </div>

</div>