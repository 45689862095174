import {
  CautareActions,
  LISTA_CMJ_ADD,
  LISTA_CMJ_DELETE,
  LISTA_CMR_ADD,
  LISTA_CMR_DELETE
} from './cautare.actions';
import { RegistruCMJ, RegistruCMR } from '../models/cautare.model';

export interface State {
  rezCautareCMJ: RegistruCMJ;
  rezCautareCMR: RegistruCMR;
}

const initialState: State = {
  rezCautareCMJ: null,
  rezCautareCMR: null
};

export function cautareReducer(state = initialState, action: CautareActions) {
  switch (action.type) {
    case LISTA_CMJ_ADD:
      return {
        ...state,
        rezCautareCMJ: action.payload
      };

    case LISTA_CMJ_DELETE:
      return {
        ...state,
        rezCautareCMJ: null
      };
    case LISTA_CMR_ADD:
      return {
        ...state,
        rezCautareCMR: action.payload
      };
    case LISTA_CMR_DELETE:
      return {
        ...state,
        rezCautareCMR: null
      };

    default:
      return state;
  }
}

export const getRezCautareCMJ = (state: State) => state.rezCautareCMJ;
export const getRezCautareCMR = (state: State) => state.rezCautareCMR;
