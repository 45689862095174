<a (click)="toggleOpen($event)" class="navigation-link" matRipple>
  <mat-icon class="navigation-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>
  <span class="navigation-link-title">{{item.title}}</span>
  <span class="navigation-link-badge" *ngIf="item.badge" [ngStyle]="{'background-color': item.badge.bg, 'color': item.badge.fg}">{{item.badge.title}}</span>
  <mat-icon class="navigation-collapse-arrow">keyboard_arrow_right</mat-icon>
</a>
<div class="navigation-children" [@slideInOut]="isOpen">
  <ng-container *ngFor="let item of item.children">
    <app-nav-item *ngIf="item.type == 'item'" [item]="item"></app-nav-item>
    <app-nav-colapse *ngIf="item.type == 'collapse'" [item]="item"></app-nav-colapse>
  </ng-container>
</div>