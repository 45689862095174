import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, AuthGuardIsAdmin, AuthGuardIsMedic } from './shared/services/auth.guard';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './autorizare/login/login.component';
import { Error404Component } from './core/error404/error404.component';
import { AppConfig } from './shared/config/app.config';

import { NavigationComponent } from './navigation/navigation.component';

const routes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
    {
        path: '',
        component: NavigationComponent,
        children: [
          {
            path: 'apps/navigation',
            loadChildren: () => import('./navigation/navigation.module').then(m => m.NavigationModule),
          },
          {
            path: 'dashboard',
            component: DashboardComponent
          },
          {
            path: 'medic',
            loadChildren: () => import('./medic/medic.module').then(m => m.MedicModule),
            canActivate: [AuthGuardIsMedic]
          },
          {
            path: 'admin',
            loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
            canActivate: [AuthGuardIsAdmin]
          },
          {
            path: 'cmj',
            loadChildren: () => import('./cmj/cmj.module').then(m => m.CmjModule)
          }
        ],
        canActivate: [AuthGuard]
      },
    { path: 'autorizare', component: LoginComponent },
    { path: AppConfig.routes.error404, component: Error404Component },
    { path: '**', redirectTo: '/' + AppConfig.routes.error404 }
];


@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: [AuthGuard, AuthGuardIsAdmin, AuthGuardIsMedic]
})
export class AppRoutingModule { }
