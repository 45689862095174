import { InjectionToken } from '@angular/core';
import { environment } from '../../../environments/environment';

export interface AppConfigInterface {
  endpoints: any;
  routes?: any;
  snackBarDuration?: number;
  snackBarVerticalPosition?: string;
  medicId?: any;
  apiKey?: string;
  production?: boolean;
}

export let APP_CONFIG = new InjectionToken('app.config');

export const AppConfig: AppConfigInterface = {
  routes: {
    // content: 'content',
    // medic: 'medic',
    error404: '404'
  },
  endpoints: {
    apiURL: environment.apiUrlEnv,
    apiAuthURL: environment.apiAuthUrlEnv
  },
  snackBarDuration: 5000,
  snackBarVerticalPosition: 'top',
  production: environment.production
};
