import { DOC_STATUS_ADD, DOC_TIP_ADD } from './registre.actions';
import { Page } from '../models/page.model';
import {
    RegistreActions,
    JUDETE_ADD,
    TARI_ADD,
    MEDIC_STATUS_ADD,
    ACT_ID_TIP_ADD,
    FACULTATE_ADD,
    FAC_DOC_TIP_ADD,
    PPU_ADD,
    PPU_GRAD_ADD,
    PPU_TIP_ADD,
    PPU_DOC_ADD,
    PPU_GRUP_ADD,
    TITLU_ADD,
    ADRESA_TIP_ADD,
    ASIGURATOR_ADD,
    CMJ_ADD,
    MEDIC_ACTIUNI_TIP_ADD,
    CMJ_SERVICII_ADD,
    EMC_TIP_ADD
} from './registre.actions';
import {
    Judet,
    Tara,
    ActIdTip,
    FacultateDocTip,
    Ppu,
    PpuGrad,
    PpuDoc,
    PpuGrup,
    Titlu,
    AdresaTip,
    Asigurator,
    Cmj,
    MedicStatus,
    Facultate,
    PpuTip,
    DocStatus,
    DocTip,
    MedicActiuniTip,
    CmjServicii,
    EmcTip
} from '../models/registre.model';


export interface State {
    tara: Tara[];
    judet: Judet[];
    act_id_tip: ActIdTip[];
    facultate: Facultate[];
    facultate_doc_tip: FacultateDocTip[];
    ppu: Ppu[];
    ppu_tip: PpuTip[];
    ppu_grad: PpuGrad[];
    ppu_doc: PpuDoc[];
    ppu_grup: PpuGrup[];
    titlu: Titlu[];
    adresa_tip: AdresaTip[];
    asigurator: Asigurator[];
    cmj: Cmj[];
    medic_status: MedicStatus[];
    doc_status: DocStatus[];
    doc_tip: DocTip[];
    actiuni_tip: MedicActiuniTip[];
    cmj_servicii: CmjServicii[];
    emc_tip: EmcTip[];
}

const initialState: State = {
    tara: [],
    judet: [],
    act_id_tip: [],
    facultate: [],
    facultate_doc_tip: [],
    ppu: [],
    ppu_tip: [],
    ppu_grad: [],
    ppu_doc: [],
    ppu_grup: [],
    titlu: [],
    adresa_tip: [],
    asigurator: [],
    cmj: [],
    medic_status: [],
    doc_status: [],
    doc_tip: [],
    actiuni_tip: [],
    cmj_servicii: [],
    emc_tip: []
};

export function registreReducer(state = initialState, action: RegistreActions) {
    switch (action.type) {
        case TARI_ADD:
            return {
                ...state,
                tara: action.payload
            };
        case JUDETE_ADD:
            return {
                ...state,
                judet: action.payload
            };
        case ACT_ID_TIP_ADD:
            return {
                ...state,
                act_id_tip: action.payload
            };
        case FACULTATE_ADD:
            return {
                ...state,
                facultate: action.payload
            };
        case FAC_DOC_TIP_ADD:
            return {
                ...state,
                facultate_doc_tip: action.payload
            };
        case PPU_ADD:
            return {
                ...state,
                ppu: action.payload
            };
        case PPU_TIP_ADD:
            return {
                ...state,
                ppu_tip: action.payload
            };
        case PPU_GRAD_ADD:
            return {
                ...state,
                ppu_grad: action.payload
            };
        case PPU_DOC_ADD:
            return {
                ...state,
                ppu_doc: action.payload
            };
        case PPU_GRUP_ADD:
            return {
                ...state,
                ppu_grup: action.payload
            };
        case TITLU_ADD:
            return {
                ...state,
                titlu: action.payload
            };
        case ADRESA_TIP_ADD:
            return {
                ...state,
                adresa_tip: action.payload
            };
        case ASIGURATOR_ADD:
            return {
                ...state,
                asigurator: action.payload
            };
        case CMJ_ADD:
            return {
                ...state,
                cmj: action.payload
            };
        case MEDIC_STATUS_ADD:
            return {
                ...state,
                medic_status: action.payload
            };
        case DOC_STATUS_ADD:
            return {
                ...state,
                doc_status: action.payload
            };
        case DOC_TIP_ADD:
            return {
                ...state,
                doc_tip: action.payload
            };
        case MEDIC_ACTIUNI_TIP_ADD:
            return {
                ...state,
                actiuni_tip: action.payload
            };
        case CMJ_SERVICII_ADD:
            return {
                ...state,
                cmj_servicii: action.payload
            };
        case EMC_TIP_ADD:
            return {
                ...state,
                emc_tip: action.payload
            };
        default:
            return state;
    }
}

export const Registre = (state: State) => state;
export const RegTara = (state: State) => state.tara;
export const RegJudet = (state: State) => state.judet;
export const RegActIdTip = (state: State) => state.act_id_tip;
export const RegFacultate = (state: State) => state.facultate;
export const RegFacDocTip = (state: State) => state.facultate_doc_tip;
export const RegPpu = (state: State) => state.ppu;
export const RegPpuTip = (state: State) => state.ppu_tip;
export const RegPpuGrad = (state: State) => state.ppu_grad;
export const RegPpuDoc = (state: State) => state.ppu_doc;
export const RegPpuGrup = (state: State) => state.ppu_grup;
export const RegTitlu = (state: State) => state.titlu;
export const RegAdresaTip = (state: State) => state.adresa_tip;
export const RegAsigurator = (state: State) => state.asigurator;
export const RegCmj = (state: State) => state.cmj;
export const RegMedicStatus = (state: State) => state.medic_status;
export const RegDocStatus = (state: State) => state.doc_status;
export const RegDocTip = (state: State) => state.doc_tip;
export const RegMedicActiuniTip = (state: State) => state.actiuni_tip;
export const RegCmjServiciiTip = (state: State) => state.cmj_servicii;
export const RegEmcTip = (state: State) => state.emc_tip;
