import { Pipe, PipeTransform } from '@angular/core';
import { Judet } from '../models/registre.model';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import { take } from 'rxjs/operators';

@Pipe({
  name: 'judet'
})
export class JudetPipe implements PipeTransform {
  numeJudet: string;
  listaJud: Judet[];

  constructor(private store: Store<fromRoot.State>) {
    this.store.select(fromRoot.getRegJudet).pipe(
      take(1)
    )
        .subscribe(res => {
          this.listaJud = res;
        });
  }

  transform(value: number): string {
    const jud = this.listaJud.filter(judet => judet.id === value);
    if (jud.length > 0) {
        return jud[0].nume;
    } else {
        return value.toString();
    }
  }

}
