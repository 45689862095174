
import { User } from '../models/user.model';
import {
    AdminActions,
    USER_LIST_ADD,
    USER_LIST_DEL
} from './admin.actions';


export interface State {
    users: User[];
}

const initialState: State = {
    users: []
};

export function adminReducer(state = initialState, action: AdminActions) {
    switch (action.type) {
        case USER_LIST_ADD:
            return {
                ...state,
                users: action.payload
            };

        case USER_LIST_DEL:
            return {
                ...state,
                users: []
            };

        default:
            return state;
    }
}

export const AdminState = (state: State) => state;
export const UsersList = (state: State) => state.users;
