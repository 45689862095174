import { Component, Input } from '@angular/core';

import { environment } from '../../../../environments/environment';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';
import * as UI from '../../../shared/redux/ui.actions';
import { Medic } from '../../../shared/models/medic.model';
import { User } from '../../../shared/models/user.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-nav-medic',
    templateUrl: './nav-medic.component.html',
    styleUrls: ['./nav-medic.component.scss']
})
export class NavMedicComponent {

    taxe = environment.taxeCmj;
    emc = environment.emcCmj;
    currentUser: User;
    currentUser$: Subscription;
    appSubsection: any; // TODO: de mutat in component separat

    @Input() medic: Medic;

    constructor(
        private store: Store<fromRoot.State>,
    ) {
        this.currentUser$ = this.store.select(fromRoot.getUserDetails).subscribe(
            user => {
                this.currentUser = user;
            }
        );
    }

    medicSelect(endpoint, medicId) {
        this.store.dispatch(new UI.MedicIdAdd(medicId));
        this.store.dispatch(new UI.AppSectionAdd('Medic'));
        this.store.dispatch(new UI.AppSubsectionAdd(endpoint));
    }

}
