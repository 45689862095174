
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpErrorResponse,
    HttpUserEvent,
    HttpResponse
} from '@angular/common/http';


import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';

import { AuthService } from '../../shared/services/auth.service';
import { ApiAuthResponse } from '../models/user.model';
import { switchMap, catchError, finalize, filter, take } from 'rxjs/operators';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
    tokenAccess: string;
    tokenRefresh: string;
    isRefreshingToken: boolean;
    tokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private store: Store<fromRoot.State>,
        private authServ: AuthService
    ) {
        const tokenAccess$ = this.store.select(fromRoot.getTokenAccess).subscribe((token: string) => {
            this.tokenAccess = token;
        });

        const tokenRefresh$ = this.store.select(fromRoot.getTokenRefresh).subscribe((token: string) => {
            this.tokenRefresh = token;
        });
    }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

        if (req.url.includes('/token')) {
            return next.handle(req);
        }

        return next.handle(this.addToken(req, this.tokenAccess)).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>error).status) {
                        case 403:
                            return this.handle403Error(req, next);
                        default:
                            return observableThrowError(error);
                    }
                } else {
                    return observableThrowError(error);
                }
            })
        );
    }

    handle403Error(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            this.tokenSubject.next(false);
            return this.authServ.refreshToken(this.tokenRefresh).pipe(
                switchMap((response: ApiAuthResponse) => {
                    if (response.token_type === 'bearer') {
                        this.tokenSubject.next(true);
                        return next.handle(this.addToken(req, this.tokenAccess));
                    } else {
                        return this.logout();
                    }
                }),
                catchError(error => {
                    console.error(error);
                    return this.logout();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                })
            );
        } else {
            return this.tokenSubject.pipe(
                filter(token => token !== false),
                take(1),
                switchMap(() => {
                    return next.handle(this.addToken(req, this.tokenAccess));
                })
            );
        }

    }

    logout() {
        this.authServ.logout();
        return observableThrowError('');
    }
}
