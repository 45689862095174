import { Component, HostListener, ElementRef, Inject, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';

import { Subscription } from 'rxjs';

import { Notificari } from '../../../shared/models/nav-notificari.model';
import { notificariDate } from '../../../app.reducer';
import { Router } from '@angular/router';
import { getNotificari } from '../../../shared/redux/alert.reducer';

@Component({
  selector: 'app-nav-notificari',
  templateUrl: './nav-notificari.component.html',
  styleUrls: ['./nav-notificari.component.scss']
})
export class NavNotificariComponent implements OnDestroy {

  notificariLista: Notificari[];
  notificariLista$: Subscription;

  cssPrefix = 'toolbar-notification';
  isOpen = false;
  notifications = [];

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement) {
      return;
    }

    const clickedInside = this._elementRef.nativeElement.contains(
      targetElement
    );
    if (!clickedInside) {
      this.isOpen = false;
    }
  }

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,

    private _elementRef: ElementRef,
    @Inject('navNotificariService') private service
  ) {
    this.notificariLista$ = this.store.select(fromRoot.notificariDate)
      .subscribe(lista => {
        this.notificariLista = lista;
      });
  }

  ngOnDestroy() {
    this.notificariLista$.unsubscribe();
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }


  delete(event, notification) {
    event.stopPropagation();
    this.service.delNotificari(notification);
  }

  itemAction(event, notification) {
    event.stopPropagation();
    switch (notification.body.type) {
      case 'transfer':
        this.router.navigateByUrl('/cmj/cmj-transfer');
        break;

      case 'cpc':
        this.router.navigateByUrl('/cmj/cert-prof-curent');
        break;

      default:
        break;
    }
  }

  refresh() {
    this.service.getNotificari();
  }

}
