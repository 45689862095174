<mat-sidenav-container [class.boxed]="layoutMode">
  <mat-sidenav class="sidenav" position="start" mode="side" [opened]="sidenavOpen" #sidenav>
    <app-nav-brand [ngClass]="settings.colorClasses.brand"></app-nav-brand>
    <app-nav-left [ngClass]="settings.colorClasses.navigation"></app-nav-left>
  </mat-sidenav>

  <div class="sidenav-container" fxLayout="column">
    <app-nav-header [sidenav]="sidenav" [ngClass]="settings.colorClasses.header"></app-nav-header>
    <div class="main-container" #scrollContainer>
      <router-outlet (activate)="onActivate($event, scrollContainer)"></router-outlet>
      <app-nav-loading *ngIf="isLoading"></app-nav-loading>
    </div>
    <app-nav-footer></app-nav-footer>
  </div>
</mat-sidenav-container>