<button mat-button [matMenuTriggerFor]="medicMenu">Medic: {{medic.nume}} {{medic.prenume}}</button>
<mat-menu #medicMenu="matMenu" overlapTrigger="false">
    <!-- <button mat-menu-item (click)="medicSelect('dashboard', medicId)">Sumar</button> -->
    <button mat-menu-item (click)="medicSelect('date-personale', medic.id)">Date personale</button>
    <button mat-menu-item (click)="medicSelect('ppu', medic.id)">Pregătire Postuniversitară</button>
    <button mat-menu-item (click)="medicSelect('alte-titluri', medic.id)">Titluri Profesionale</button>
    <button mat-menu-item (click)="medicSelect('adresa', medic.id)">Contact</button>
    <div *ngIf="emc.includes(currentUser.judet_id)">
        <button mat-menu-item (click)="medicSelect('emc', medic.id)" *ngIf="emc.includes(currentUser.judet_id)">E.M.C.</button>
    </div>
    <mat-divider></mat-divider>
    <button mat-menu-item [matMenuTriggerFor]="medicDocumente">Documente</button>
    <div *ngIf="taxe.includes(currentUser.judet_id)">
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="medicSelect('taxe', medic.id)">Taxe</button>
    </div>
    <mat-divider></mat-divider>
    <button mat-menu-item [matMenuTriggerFor]="medicActiuni">Actiuni</button>
</mat-menu>

<mat-menu #medicDocumente="matMenu">
    <button mat-menu-item (click)="medicSelect('certificat', medic.id)">Certificat de membru</button>
    <button mat-menu-item (click)="medicSelect('avizare', medic.id)">Avizare</button>
    <button mat-menu-item (click)="medicSelect('cpc', medic.id)">Certificat profesional curent</button>
</mat-menu>

<mat-menu #medicActiuni="matMenu">
    <button mat-menu-item (click)="medicSelect('transfer', medic.id)">Transfer</button>
    <button mat-menu-item (click)="medicSelect('status', medic.id)">Status</button>
</mat-menu>