<div class="navigation-container">
  <div class="navigation-scrollbar-container">
    <perfect-scrollbar>
      <ng-container *ngFor="let navigation of navigationModel">
        <app-nav-colapse *ngIf="navigation.type == 'collapse'" [item]="navigation"></app-nav-colapse>
        <app-nav-item *ngIf="navigation.type == 'item'" [item]="navigation"></app-nav-item>
      </ng-container>

    </perfect-scrollbar>
  </div>

  <!-- <div class="navigation-footer">
    <a matRipple href="https://comprami.ro" target="blank">
      <mat-icon>help</mat-icon>
      Ajutor și suport</a>
  </div> -->
</div>