<section>
    <div fxLayoutAlign="center center" *ngIf="+total <= 0">
        <h1>Nu există rezultate</h1>
    </div>
    <div *ngIf="+total > 0">
        <div fxLayoutAlign="center center">
            <mat-form-field fxFlex="40%">
                <input matInput type="text" (keyup)="doFilter($any($event.target).value)"
                    placeholder="Filtrează datele tabelului">
            </mat-form-field>
        </div>
        <div fxLayoutAlign="center center" *ngIf="+pagini >= 2">
            <mat-list>
                <mat-list-item align="end">
                    <button mat-icon-button (click)="getNewPage(prevPage, 'cmj')" color="primary"
                        *ngIf="currentPage >= 2">
                        <mat-icon aria-label="Pagina anterioara">keyboard_arrow_left</mat-icon>
                    </button>
                    <button mat-button color="primary" [disabled]="true">Pagina {{currentPage}} din {{pagini}}</button>
                    <button mat-icon-button (click)="getNewPage(nextPage, 'cmj')" color="primary"
                        *ngIf="currentPage < pagini">
                        <mat-icon aria-label="Pagina urmatoare">keyboard_arrow_right</mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" matSort style="width: 100%">

                <ng-container matColumnDef="status_icon">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon [color]="getColor(element.status_id)">{{regServ.iconMedicStatus(element.status_id)}}
                        </mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let element">{{ element.status_id | medic_status}}</td>
                </ng-container>

                <ng-container matColumnDef="nume">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nume</th>
                    <td mat-cell *matCellDef="let element">{{ element.nume }}</td>
                </ng-container>

                <ng-container matColumnDef="prenume">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Prenume</th>
                    <td mat-cell *matCellDef="let element">{{ element.prenume }}</td>
                </ng-container>

                <ng-container matColumnDef="nume_anterior">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>N. Anterior</th>
                    <td mat-cell *matCellDef="let element">{{ element.nume_anterior }}</td>
                </ng-container>

                <ng-container matColumnDef="cuim">
                    <th mat-header-cell *matHeaderCellDef>C.U.I.M</th>
                    <td mat-cell *matCellDef="let element">{{ element.cuim }}</td>
                </ng-container>

                <ng-container matColumnDef="cnp">
                    <th mat-header-cell *matHeaderCellDef>C.N.P</th>
                    <td mat-cell *matCellDef="let element">{{ element.cnp }}</td>
                </ng-container>

                <ng-container matColumnDef="modificare">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-button color="primary" [matMenuTriggerFor]="menu">Modifică</button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="medicSelect('date-personale', element.id)">Date
                                personale</button>
                            <button mat-menu-item (click)="medicSelect('ppu', element.id)">Pregătire
                                Postuniversitară</button>
                            <button mat-menu-item (click)="medicSelect('alte-titluri', element.id)">Titluri
                                Profesionale</button>
                            <button mat-menu-item (click)="medicSelect('adresa', element.id)">Contact</button>
                            <button mat-menu-item (click)="medicSelect('emc', element.id)"
                                *ngIf="emc.includes(currentUser.judet_id)">E.M.C.</button>
                        </mat-menu>
                    </td>
                </ng-container>

                <ng-container matColumnDef="documente">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-button color="primary" [matMenuTriggerFor]="menuDoc">Documente</button>
                        <mat-menu #menuDoc="matMenu">
                            <button mat-menu-item (click)="medicSelect('certificat', element.id)">Certificat de
                                membru</button>
                            <button mat-menu-item (click)="medicSelect('avizare', element.id)">Avizare</button>
                            <button mat-menu-item (click)="medicSelect('cpc', element.id)">Certificat profesional
                                curent</button>
                            <span *ngIf="!prod">
                            </span>
                        </mat-menu>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actiuni">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-button color="primary" [matMenuTriggerFor]="menuActiuni">Acțiuni</button>
                        <mat-menu #menuActiuni="matMenu">
                            <button mat-menu-item (click)="medicSelect('transfer', element.id)">Transfer</button>
                            <button mat-menu-item (click)="medicSelect('status', element.id)">Status</button>
                        </mat-menu>
                    </td>
                </ng-container>

                <ng-container matColumnDef="taxe" *ngIf="taxe.includes(currentUser.judet_id)">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-button color="primary" (click)="medicSelect('taxe', element.id)">Taxe</button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row-pointer"
                    [ngClass]="{'highlight': selectedRowIndex == row.id}" (click)="clickRow(row)"></tr>
            </table>
        </div>
    </div>
</section>