import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as CautareMedic from '../../shared/redux/cautare.actions';
import * as UI from '../../shared/redux/ui.actions';
import * as Medic from '../../shared/redux/medic.actions';

import { User } from '../../shared/models/user.model';
import { CautareService } from '../../shared/services/cautare.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cautare',
  templateUrl: './cautare.component.html',
  styleUrls: ['./cautare.component.scss']
})
export class CautareComponent implements OnInit, OnDestroy {
  searchFormCMJ: UntypedFormGroup;
  searchFormCMR: UntypedFormGroup;
  user: User;
  userSub: Subscription;
  isLoading: boolean;
  isLoadingSub: Subscription;

  constructor(
    private store: Store<fromRoot.State>,
    private cautareMedic: CautareService,
    private router: Router
  ) { }

  ngOnInit() {
    this.setForm();
    this.userSub = this.store.select(fromRoot.getUserDetails).subscribe(user => {
      this.user = user;
    });
    this.isLoadingSub = this.store.select(fromRoot.getIsLoading)
      .subscribe(e => (this.isLoading = e));
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
    this.isLoadingSub.unsubscribe();
  }
  setForm() {
    this.searchFormCMJ = new UntypedFormGroup({
      searchCMJ: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(3)
      ])
    });
    this.searchFormCMR = new UntypedFormGroup({
      searchCMR: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(3)
      ])
    });
  }

  onSearch(searchVal: string, searchType: string) {
    if ((this.searchFormCMJ.valid || this.searchFormCMR.valid) && !this.isLoading) {
      this.cautareMedic.cautaMedic(searchVal, searchType);
    }
    switch (searchType) {
      case 'cmj':
        this.store.dispatch(new CautareMedic.ListaCMRDelete());
        this.searchFormCMR.get('searchCMR').setValue(null);
        break;
      case 'cmr':
        this.store.dispatch(new CautareMedic.ListaCMJDelete());
        this.searchFormCMJ.get('searchCMJ').setValue(null);
        break;
      default:
        break;
    }

  }

  onSearchFocus(field: string) {
  }

  clearSearch(item: string) {
    switch (item) {
      case 'cmj':
        this.store.dispatch(new CautareMedic.ListaCMJDelete());
        this.searchFormCMJ.get('searchCMJ').setValue(null);
        return;
      case 'cmr':
        this.store.dispatch(new CautareMedic.ListaCMRDelete());
        this.searchFormCMR.get('searchCMR').setValue(null);
        return;

      default:
        break;
    }
  }

  medicNou() {
    this.store.dispatch(new UI.MedicIdRemove());
    this.store.dispatch(new Medic.UnsetMedicData());
    this.store.dispatch(new UI.MedicIdAdd(null));
    this.store.dispatch(new UI.MedicNumeAdd('Nou'));
    this.store.dispatch(new UI.AppSectionAdd('Medic'));
    this.store.dispatch(new UI.AppSubsectionAdd('nou'));
    this.router.navigate(['/medic/nou']);
  }
}
