import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../config/app.config';

@Injectable({
  providedIn: 'root'
})
export class UploadDownloadFileService {

  private apiUrl = AppConfig.endpoints.apiURL;

  constructor(private httpClient: HttpClient) { }

  public upload(formData, apiEndPoint) {
    return this.httpClient.post(
      `${this.apiUrl}/${apiEndPoint}`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob'
      },
    );
  }

  public download(apiEndPoint) {
    return this.httpClient.get(
      `${this.apiUrl}/${apiEndPoint}`,
      {
        observe: 'events',
        responseType: 'blob'
      },
    );
  }
}
