<section fxLayout="row" fxLayoutGap="10px" fxLayout.lt-sm="column" fxFlex="100%" fxFlexFill>
  <mat-card fxFlex="1 1 auto" fxFlexFill>
    <mat-card-title>Registru C.M. {{ user.judet_id | judet}}</mat-card-title>
    <mat-card-content>
      <form [formGroup]="searchFormCMJ" (ngSubmit)="onSearch(searchFormCMJ.value.searchCMJ, 'cmj')">
        <mat-form-field fxFlex="1 1 auto" fxFlexFill>
          <input matInput formControlName="searchCMJ" type="text" placeholder="Caută medic" required (focus)="onSearchFocus('cmj')">
          <mat-icon matSuffix>search</mat-icon>
          <mat-hint align="start">Se pot folosi: Nume / Prenume / CNP / CUIM</mat-hint>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
      <!-- TODO: de revazut fxlayout se afiseaza aiurea -->
      <button mat-raised-button color="primary" (click)="medicNou()">Adaugă un medic nou</button>
      <span fxFlex="1 30 auto" fxFill></span>
      <button mat-button [disabled]="searchFormCMJ.invalid || isLoading" (click)="clearSearch('cmj')">Șterge rezultate</button>
      <button mat-button [disabled]="searchFormCMJ.invalid || isLoading" color="primary" (click)="onSearch(searchFormCMJ.value.searchCMJ, 'cmj')">Căutare</button>
    </mat-card-actions>
  </mat-card>

  <mat-card fxFlex="1 1 auto" fxFlexFill>
    <mat-card-title>Registru Național</mat-card-title>
    <mat-card-content>
      <form [formGroup]="searchFormCMR" (ngSubmit)="onSearch(searchFormCMR.value.searchCMR, 'cmr')">
        <section>
          <mat-form-field fxFlex="100%" fxFlexFill>
            <input matInput formControlName="searchCMR" type="text" placeholder="Caută medic în registrul național" required (focus)="onSearchFocus('cmr')"
            />
            <mat-icon matSuffix>search</mat-icon>
            <mat-hint align="start">Se pot folosi: Nume / Prenume / CNP / CUIM</mat-hint>
          </mat-form-field>
        </section>
      </form>
    </mat-card-content>
    <mat-card-actions fxLayout="row" fxLayoutAlign="end center" fxLayout.lt-sm="column" >
      <button mat-button [disabled]="searchFormCMR.invalid || isLoading" (click)="clearSearch('cmr')">Șterge rezultate</button>
      <button mat-button [disabled]="searchFormCMR.invalid || isLoading" color="primary" (click)="onSearch(searchFormCMR.value.searchCMR, 'cmr')">Căutare</button>
    </mat-card-actions>
  </mat-card>
</section>