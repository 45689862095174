import { Action } from '@ngrx/store';
import { RegistruCMJ, RegistruCMR } from '../models/cautare.model';

export const LISTA_CMJ_ADD = '[Cautare] Add lista CMJ';
export const LISTA_CMJ_DELETE = '[Cautare] Delete lista CMJ';

export const LISTA_CMR_ADD = '[Cautare] Add lista CMR';
export const LISTA_CMR_DELETE = '[Cautare] Delete lista CMR';


export class ListaCMJAdd implements Action {
    readonly type = LISTA_CMJ_ADD;

    constructor(public payload: RegistruCMJ) {}
}

export class ListaCMJDelete implements Action {
    readonly type = LISTA_CMJ_DELETE;
}

export class ListaCMRAdd implements Action {
    readonly type = LISTA_CMR_ADD;

    constructor(public payload: RegistruCMR) {}
}

export class ListaCMRDelete implements Action {
    readonly type = LISTA_CMR_DELETE;
}






export type CautareActions = ListaCMJAdd | ListaCMJDelete |ListaCMRAdd | ListaCMRDelete;
