<section>
  <div fxLayoutAlign="center center" *ngIf="+total <= 0">
    <h1>Nu există rezultate</h1>
  </div>
  <div *ngIf="+total > 0">

    <div fxLayoutAlign="center center">
      <mat-form-field fxFlex="40%">
        <input matInput type="text" (keyup)="doFilter($any($event.target).value)"
          placeholder="Filtrează datele tabelului">
      </mat-form-field>
    </div>

    <div fxLayoutAlign="center center" *ngIf="+pagini >= 2">
      <mat-list>
        <mat-list-item align="end">
          <button mat-icon-button (click)="getNewPage(prevPage, 'cmr')" color="primary" *ngIf="currentPage >= 2">
            <mat-icon aria-label="Pagina anterioara">keyboard_arrow_left</mat-icon>
          </button>
          <button mat-button color="primary" [disabled]="true">Pagina {{currentPage}} din {{pagini}}</button>
          <button mat-icon-button (click)="getNewPage(nextPage, 'cmr')" color="primary" *ngIf="currentPage < pagini">
            <mat-icon aria-label="Pagina urmatoare">keyboard_arrow_right</mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </div>

    <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort style="width: 100%">

        <ng-container matColumnDef="status_icon">
          <!-- <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.status === 1" color='primary'>check_circle</mat-icon>
          <mat-icon *ngIf="element.status === 2" color='warn'>error_outline</mat-icon>
          <mat-icon *ngIf="element.status === 3">check_circle</mat-icon>
          <mat-icon *ngIf="element.status === 6" color='warn'>autorenew</mat-icon>
          <mat-icon *ngIf="element.status === 11">access_time</mat-icon>
        </mat-cell> -->

          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let element">
            <mat-icon [color]="getColor(element.status_id)">{{regServ.iconMedicStatus(element.status_id)}}</mat-icon>
          </td>
        </ng-container>



        <ng-container matColumnDef="judet">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Județ</th>
          <td mat-cell *matCellDef="let element">{{ element.judet_id | judet }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element">{{ element.status_id | medic_status }}</td>
        </ng-container>

        <ng-container matColumnDef="nume">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nume</th>
          <td mat-cell *matCellDef="let element">{{ element.nume }}</td>
        </ng-container>

        <ng-container matColumnDef="prenume">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Prenume</th>
          <td mat-cell *matCellDef="let element">{{ element.prenume }}</td>
        </ng-container>


        <ng-container matColumnDef="nume_anterior">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>N. Anterior</th>
          <td mat-cell *matCellDef="let element">{{ element.nume_anterior }}</td>
        </ng-container>

        <ng-container matColumnDef="cuim">
          <th mat-header-cell *matHeaderCellDef>C.U.I.M</th>
          <td mat-cell *matCellDef="let element">{{ element.cuim }}</td>
        </ng-container>

        <ng-container matColumnDef="cnp">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>C.N.P</th>
          <td mat-cell *matCellDef="let element">{{ element.cnp }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row-pointer"
          [ngClass]="{'highlight': selectedRowIndex == row.id}" (click)="clickRow(row)"></tr>
      </table>
    </div>

  </div>
</section>