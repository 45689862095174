import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';

import { MedicRegistru } from '../../shared/models/cautare.model';
import { CautareService } from '../../shared/services/cautare.service';
import { RegistreService } from '../../shared/services/registre.service';

@Component({
  selector: 'app-rezultat-cmr',
  templateUrl: './rezultat-cmr.component.html',
  styleUrls: ['./rezultat-cmr.component.scss']
})
export class RezultatCmrComponent implements OnInit, AfterViewInit {

  displayedColumns = ['status_icon', 'judet', 'status', 'nume', 'prenume', 'nume_anterior', 'cuim', 'cnp'];

  dataSource = new MatTableDataSource<MedicRegistru>();

  selectedRowIndex = -1;

  pagini: number;
  currentPage: number;
  nextPage: string;
  prevPage: string;
  total: number;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private cautareMedic: CautareService,
    private store: Store<fromRoot.State>,
    public regServ: RegistreService
  ) { }

  ngOnInit() {
    this.store.select(fromRoot.getCautareMedicCMR).subscribe(lista => {
      if (lista != null) {
        this.dataSource.data = lista.results;

        this.pagini = lista.pages;
        this.nextPage = lista.next;
        this.prevPage = lista.prev;
        this.total = lista.total;

        const prevPg = this.prevPage.match(/=\s*(.*)$/);
        const nextPg = this.nextPage.match(/=\s*(.*)$/);

        if (+nextPg[1] === 2) {
          this.currentPage = 1;
        } else {
          this.currentPage = +prevPg[1] + 1;
        }
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

  clickRow(row) {
    this.selectedRowIndex = row.id;
  }

  getNewPage(searchVal, searchType) {
    this.cautareMedic.cautaMedic(searchVal, searchType);
  }

  getColor(statusId) {

    if ([1, 3].includes(statusId)) {
      return 'primary';
    }

    if ([2].includes(statusId)) {
      return 'accent';
    }

    return 'warn';
  }
}
