import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NavHeaderComponent } from './nav-header.component';
import { SharedModule } from '../../shared/modules/shared.module';
import { NavUserComponent } from './nav-user/nav-user.component';
import { NavNotificariComponent } from './nav-notificari/nav-notificari.component';
import { NavNotificariService } from './nav-notificari/nav-notificari.service';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NavMedicComponent } from './nav-medic/nav-medic.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    PerfectScrollbarModule
  ],
  declarations: [
    NavHeaderComponent,
    NavUserComponent,
    NavNotificariComponent,
    NavMedicComponent
  ],
  providers: [
    {provide: 'navNotificariService', useClass: NavNotificariService},
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  exports: [
    NavHeaderComponent
  ]
})
export class NavHeaderModule { }
