import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';

import { NavLeftService } from './nav-left.service';

@Component({
  selector: 'app-nav-left',
  templateUrl: './nav-left.component.html',
  styleUrls: ['./nav-left.component.scss']
})
export class NavLeftComponent implements OnInit, OnDestroy {

  navigationModel: any[];
  navigationModelChangeSubscription$: Subscription;

  isAdmin$: Subscription;
  isAdmin = false;

  adminMenuItems = {
    'id': 'admin',
    'title': 'Admin',
    'type': 'collapse',
    'icon': 'star_border',
    'children': [
      {
        'id': 'users',
        'title': 'Utilizatori',
        'type': 'item',
        'url': '/admin/users'
      },
      {
        'id': 'registre',
        'title': 'Registre',
        'type': 'item',
        'url': '/admin/registre'
      },
      {
        'id': 'medic',
        'title': 'Medic',
        'type': 'collapse',
        'children': [
          {
            'id': 'cuim',
            'title': 'CUIM',
            'type': 'item',
            'url': '/admin/medic/cuim'
          },
          {
            'id': 'facultate',
            'title': 'Facultate',
            'type': 'item',
            'url': '/admin/medic/facultate'
          },
          {
            'id': 'status',
            'title': 'Status',
            'type': 'item',
            'url': '/admin/medic/status'
          },
          {
            'id': 'regMed',
            'title': 'Registru Public',
            'type': 'item',
            'url': '/admin/medic/reg-med'
          },
          {
            'id': 'regMed',
            'title': 'CMMB actualizare',
            'type': 'item',
            'url': '/admin/medic/cmmb'
          },
          {
            'id': 'aviz',
            'title': 'Aviz',
            'type': 'item',
            'url': '/admin/medic/doc/aviz'
          }
          
        ]
      },
      // {
      //   'id': 'test',
      //   'title': 'Teste',
      //   'type': 'collapse',
      //   'children': [
      //     {
      //       'id': 'testUpload',
      //       'title': 'Upload',
      //       'type': 'item',
      //       'url': '/admin/test/upload'
      //     }]
      // }
    ]
  };


  constructor(
    private service: NavLeftService,
    private store: Store<fromRoot.State>
  ) {
    this.navigationModelChangeSubscription$ = this.service.onNavigationModelChange.subscribe(
      navigation => {
        this.navigationModel = navigation;
      }
    );

    this.isAdmin$ = this.store.select(fromRoot.getIsAdministrator).subscribe(
      isAdmin => {
        this.isAdmin = isAdmin;
      }
    );
  }

  ngOnInit() {
    if (this.isAdmin) {
      this.navigationModel.push(this.adminMenuItems);
    }
    // this.addSubitemToNavigation();
  }

  ngOnDestroy() {
    this.isAdmin$.unsubscribe();
    this.navigationModelChangeSubscription$.unsubscribe();
  }

  updateExtendBadge() {
    const extendNavItem = this.service.getNavigationItem('extend');
    extendNavItem.badge.title = 6;
  }

  addSubitemToNavigation() {
    const newNavItem = {
      id: 'item',
      title: 'Adăugare dinamică a meniului',
      type: 'item',
      url: '/apps/navigation'
    };

    this.service.addNavigationItem('medic', newNavItem);
  }

}
