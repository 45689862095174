import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { shareReplay, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as Med from '../redux/medic.actions';
import * as UI from '../redux/ui.actions';

import { ApiService } from './api.service';
import { SnackBarService } from './snackbar.service';
import { AlertsService } from './alerts.service';

@Injectable({
  providedIn: 'root'
})

export class TransferService {

  constructor(
    private store: Store<fromRoot.State>,
    private apiService: ApiService,
    private snackBar: SnackBarService,
    public alertServ: AlertsService
  ) { }

  medicStartTransferV2(data) {
    this.apiService.startTransferV2(data).pipe(
      shareReplay()
    )
      .subscribe(
        (response) => {
          // this.snackBar.showSnackbar(response.msg);
          this.snackBar.showSnackbar('Datele au fost actualizate.');
          this.getMedicData(data.medicId);
          this.store.dispatch(new Med.SetMedicTransfer(response));
        },
        (error: HttpErrorResponse) => {
          this.snackBar.showError(error);
        }
      );
  }

  medicTransferV2(data) {
    this.apiService.transferV2(data).pipe(
      shareReplay()
    )
      .subscribe(
        (response) => {
          // this.snackBar.showSnackbar(response.msg);
          this.snackBar.showSnackbar('Datele au fost actualizate.');
          if (data.judetSursa) {
            this.getMedicData(data.medicId);
          } else {
            this.alertServ.getAlert('transfer');
          }
          if (data.pas === 'delete' || [10, 5].includes(response.status)) {
            this.store.dispatch(new UI.AppSubsectionStateAdd(null));
          } else {
            this.store.dispatch(new Med.SetMedicTransfer(response));
          }
        },
        (error: HttpErrorResponse) => {
          this.snackBar.showError(error);
        }
      );
  }

  medicAddTransferIstoric(medicId, endpoint, data) {
    this.apiService.postItem(data, endpoint).pipe(
      shareReplay()
    )
      .subscribe(
        (response) => {
          // this.snackBar.showSnackbar(response.msg);
          this.snackBar.showSnackbar('Datele au fost actualizate.');
          this.getMedicData(medicId);
          this.store.dispatch(new UI.AppSubsectionStateAdd(null));
        },
        (error: HttpErrorResponse) => {
          this.snackBar.showError(error);
        }
      );
  }

  // TODO: de verificat daca putem scoate getMedicData din modulul medic
  getMedicData(idMedic: string) {
    if (!idMedic) {
      return;
    }
    this.store.dispatch(new UI.StartLoading());
    const answer = this.apiService.getMedicData(idMedic).pipe(
      tap(() => this.store.dispatch(new UI.StopLoading())),
      shareReplay()
    )
      .subscribe(
        (response) => {
          this.store.dispatch(new Med.SetMedicData(response));
          this.store.dispatch(new UI.MedicNumeAdd(response.nume + ' ' + response.prenume));
          // this.store.dispatch(new UI.MedicIdAdd(response.medic.id));
        },
        (error: HttpErrorResponse) => {
          this.snackBar.showError(error);
        }
      );
  }
}
