import { Component } from '@angular/core';

@Component({
  selector: 'app-nav-brand',
  templateUrl: './nav-brand.component.html',
  styleUrls: ['./nav-brand.component.scss']
})
export class NavBrandComponent  {

  constructor() { }

}
