import { Component, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromRoot from './app.reducer';
import { RegistreService } from './shared/services/registre.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('sidenav')
  public sidenav: MatSidenav;

  title = 'app';
  showHeader$: Observable<boolean>;
  showSidenav$: Observable<boolean>;
  progressBar: string;

  constructor(
    private store: Store<fromRoot.State>,
    private regServ: RegistreService,
    private cdRef: ChangeDetectorRef
  ) {
    this.showHeader$ = this.store.select(fromRoot.getIsAuthenticated);
  }

  ngOnInit() {
    this.store.select(fromRoot.getSidenavShow).subscribe(state => {
      if (this.sidenav) {
        if (state) {
          this.sidenav.open();
        } else {
          this.sidenav.close();
        }
      }
    });
    this.regServ.getJudet();
    this.store.select(fromRoot.getIsLoading).subscribe(state => {
      if (state) {
        this.progressBar = 'indeterminate';
      } else {
        this.progressBar = 'determinate';
      }
    });
    this.store.select(fromRoot.getIsLoading).subscribe(state => {
      if (state) {
        this.progressBar = 'indeterminate';
      } else {
        this.progressBar = 'determinate';
      }
      this.cdRef.detectChanges();
    });
  }
}
