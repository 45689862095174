import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromRoot from '../app.reducer';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  isLoading = false;

  layoutMode = false;

  sidenavOpen = false;
  sidenavMode = 'side';
  // sideNavMode = 'push';
  sidenavAlign = 'start';

  settings = {
    colorClasses: {
      header: 'warn',
      footer: 'stbui-background-blue',
      brand: 'total',
      navigation: 'stbui-background-dark'
    },
    layout: {
      mode: 'fullwidth',
      navigation: 'left',
      header: 'below',
      footer: 'below'
    }
  };

  constructor(
    private store: Store<fromRoot.State>,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.store.select(fromRoot.getIsLoading).subscribe(state => {
      if (state) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
      this.cd.detectChanges();
    });
  }

  onActivate(event, scrollContainer) {
    scrollContainer.scrollTop = 0;
  }

}
