import { Action } from '@ngrx/store';
import { EmcTip } from '../models/registre.model';
import {
    Judet,
    Tara,
    ActIdTip,
    FacultateDocTip,
    Ppu,
    PpuGrad,
    PpuDoc,
    PpuGrup,
    Titlu,
    AdresaTip,
    Asigurator,
    Cmj,
    MedicStatus,
    PpuTip,
    Facultate,
    DocStatus,
    DocTip,
    MedicActiuniTip,
    CmjServicii
} from '../models/registre.model';

export const TARI_ADD = '[Reg] Add tari';
export const JUDETE_ADD = '[Reg] Add judete';
export const ACT_ID_TIP_ADD = '[Reg] Add tip act identitate';
export const FACULTATE_ADD = '[Reg] Add facultate';
export const FAC_DOC_TIP_ADD = '[Reg] Add tip act facultate';
export const PPU_ADD = '[Reg] Add pregatire postuniversitara';
export const PPU_TIP_ADD = '[Reg] Add tip pregatire postuniversitara';
export const PPU_GRAD_ADD = '[Reg] Add preg postuniv grad';
export const PPU_DOC_ADD = '[Reg] Add preg postuniv tip document';
export const PPU_GRUP_ADD = '[Reg] Add preg postuniv gurp specialitati medicale';
export const TITLU_ADD = '[Reg] Add alte titluri profesionale';
export const ADRESA_TIP_ADD = '[Reg] Add tipuri de adrese';
export const ASIGURATOR_ADD = '[Reg] Add asigurator';
export const CMJ_ADD = '[Reg] Add detali CMJ';
export const MEDIC_STATUS_ADD = '[Reg] Add status medic';
export const DOC_STATUS_ADD = '[Reg] Add registru document status';
export const DOC_TIP_ADD = '[Reg] Add registru document tip';
export const MEDIC_ACTIUNI_TIP_ADD = '[Reg] Add registru medic actiuni tip';
export const CMJ_SERVICII_ADD = '[Reg] Add registru cmj servicii';
export const EMC_TIP_ADD = '[Reg] Add registru emc tip';

export class TariAdd implements Action {
    readonly type = TARI_ADD;

    constructor(public payload: Tara[]) { }
}

export class JudeteAdd implements Action {
    readonly type = JUDETE_ADD;

    constructor(public payload: Judet[]) { }
}

export class ActIdTipAdd implements Action {
    readonly type = ACT_ID_TIP_ADD;

    constructor(public payload: ActIdTip[]) { }
}

export class FacultateAdd implements Action {
    readonly type = FACULTATE_ADD;

    constructor(public payload: Facultate[]) { }
}

export class FacDocTipAdd implements Action {
    readonly type = FAC_DOC_TIP_ADD;

    constructor(public payload: FacultateDocTip[]) { }
}

export class PpuAdd implements Action {
    readonly type = PPU_ADD;

    constructor(public payload: Ppu[]) { }
}

export class PpuTipAdd implements Action {
    readonly type = PPU_TIP_ADD;

    constructor(public payload: PpuTip[]) { }
}

export class PpuGradAdd implements Action {
    readonly type = PPU_GRAD_ADD;

    constructor(public payload: PpuGrad[]) { }
}

export class PpuDocAdd implements Action {
    readonly type = PPU_DOC_ADD;

    constructor(public payload: PpuDoc[]) { }
}

export class PpuGrupAdd implements Action {
    readonly type = PPU_GRUP_ADD;

    constructor(public payload: PpuGrup[]) { }
}

export class TitluAdd implements Action {
    readonly type = TITLU_ADD;

    constructor(public payload: Titlu[]) { }
}

export class AdresaTipAdd implements Action {
    readonly type = ADRESA_TIP_ADD;

    constructor(public payload: AdresaTip[]) { }
}

export class AsiguratorAdd implements Action {
    readonly type = ASIGURATOR_ADD;

    constructor(public payload: Asigurator[]) { }
}

export class CmjAdd implements Action {
    readonly type = CMJ_ADD;

    constructor(public payload: Cmj[]) { }
}

export class MedicStatusAdd implements Action {
    readonly type = MEDIC_STATUS_ADD;

    constructor(public payload: MedicStatus[]) { }
}

export class DocStatusAdd implements Action {
    readonly type = DOC_STATUS_ADD;

    constructor(public payload: DocStatus[]) { }
}

export class DocTipAdd implements Action {
    readonly type = DOC_TIP_ADD;

    constructor(public payload: DocTip[]) { }
}

export class MedicActiuniTipAdd implements Action {
    readonly type = MEDIC_ACTIUNI_TIP_ADD;

    constructor(public payload: MedicActiuniTip[]) { }
}

export class CmjServiciiAdd implements Action {
    readonly type = CMJ_SERVICII_ADD;

    constructor(public payload: CmjServicii[]) { }
}

export class EmcTipAdd implements Action {
    readonly type = EMC_TIP_ADD;

    constructor(public payload: EmcTip[]) { }
}


export type RegistreActions = JudeteAdd | TariAdd | ActIdTipAdd | FacultateAdd |
    FacDocTipAdd | PpuAdd | PpuTipAdd | PpuGradAdd | PpuDocAdd | PpuGrupAdd |
    TitluAdd | AdresaTipAdd | AsiguratorAdd | CmjAdd | MedicStatusAdd |
    DocStatusAdd | DocTipAdd | MedicActiuniTipAdd | CmjServiciiAdd | EmcTipAdd;
