import {
    MedicActions,
    SET_MEDIC_DATA,
    UNSET_MEDIC_DATA,
    SET_MEDIC_CERT_DRAFT,
    UNSET_MEDIC_CERT_DRAFT,
    UNSET_MEDIC_AVIZ_DRAFT,
    SET_MEDIC_AVIZ_DRAFT,
    SET_MEDIC_CERT_PROF_CURENT_DRAFT,
    UNSET_MEDIC_CERT_PROF_CURENT_DRAFT,
    SET_MEDIC_TRANSFER,
    UNSET_MEDIC_TRANSFER
} from './medic.actions';

import { Medic, MedicEmcTotal } from '../models/medic.model';
import { DocCertificat, DocAvizare, DocCertProfCurent } from '../models/medic-doc.model';
import { medicCertDraft, medicAvizDraft, medicCertProfCurentDraft } from '../../app.reducer';
import { Transfer } from '../models/medic-actiuni.model';
import { SET_MEDIC_EMC_TOTAL, UNSET_MEDIC_EMC_TOTAL } from './medic.actions';

export interface State {
    medicData: Medic;
    medicCertDraft: DocCertificat;
    medicAvizDraft: DocAvizare;
    medicCertProfCurentDraft: DocCertProfCurent;
    Transfer: Transfer;
    medicEmcTotal: MedicEmcTotal;
}

const initialState: State = {
    medicData: null,
    medicCertDraft: null,
    medicAvizDraft: null,
    medicCertProfCurentDraft: null,
    Transfer: null,
    medicEmcTotal: null
};

export function medicReducer(state = initialState, action: MedicActions) {
    switch (action.type) {
        case SET_MEDIC_DATA:
            return {
                ...state,
                medicData: action.payload
            };
        case UNSET_MEDIC_DATA:
            return {
                ...state,
                medicData: null,
                medicCertDraft: null,
                medicAvizDraft: null,
                medicCertProfCurentDraft: null,
                medicEmcTotal: null,
            };
        case SET_MEDIC_CERT_DRAFT:
            return {
                ...state,
                medicCertDraft: action.payload
            };
        case UNSET_MEDIC_CERT_DRAFT:
            return {
                ...state,
                medicCertDraft: null
            };
        case SET_MEDIC_AVIZ_DRAFT:
            return {
                ...state,
                medicAvizDraft: action.payload
            };
        case UNSET_MEDIC_AVIZ_DRAFT:
            return {
                ...state,
                medicAvizDraft: null
            };
        case SET_MEDIC_CERT_PROF_CURENT_DRAFT:
            return {
                ...state,
                medicCertProfCurentDraft: action.payload
            };
        case UNSET_MEDIC_CERT_PROF_CURENT_DRAFT:
            return {
                ...state,
                medicCertProfCurentDraft: null
            };
        case SET_MEDIC_TRANSFER:
            return {
                ...state,
                Transfer: action.payload
            };
        case UNSET_MEDIC_TRANSFER:
            return {
                ...state,
                Transfer: null
            };
        case SET_MEDIC_EMC_TOTAL:
            return {
                ...state,
                medicEmcTotal: action.payload
            };
        case UNSET_MEDIC_EMC_TOTAL:
            return {
                ...state,
                medicEmcTotal: null
            };

        default:
            return state;
    }
}

export const MedicData = (state: State) => state.medicData;
export const MedicCertDraftData = (state: State) => state.medicCertDraft;
export const MedicAvizDraftData = (state: State) => state.medicAvizDraft;
export const MedicCertProfCurentData = (state: State) => state.medicCertProfCurentDraft;
export const MedicTransfer = (state: State) => state.Transfer;
export const MedicEmcTotalData = (state: State) => state.medicEmcTotal;

