import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as UI from '../../shared/redux/ui.actions';
import * as Auth from '../../shared/redux/auth.actions';

import { AuthService } from '../../shared/services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  prod = environment.production;
  userForm: UntypedFormGroup;
  componentMode = 'default';
  show = false;
  type = 'password';
  isLoading: boolean;
  isLoadingSub: Subscription;
  isAuth: boolean;
  isAuthSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private authServ: AuthService,
    private store: Store<fromRoot.State>
  ) {}

  ngOnInit() {
    this.isAuthSub = this.store.select(fromRoot.getIsAuthenticated).subscribe(e => {
      this.isAuth = e;
    });
    this.isLoadingSub = this.store
      .select(fromRoot.getIsLoading)
      .subscribe(e => (this.isLoading = e));
    this.createForm();
  }

  ngOnDestroy() {
    this.isAuthSub.unsubscribe();
    this.isLoadingSub.unsubscribe();
  }

  createForm() {
    this.userForm = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
      password: [
        null,
        [Validators.required, Validators.minLength(8), Validators.maxLength(32)]
      ]
    });
  }

  onLogin() {
    // TODO: test
    if (this.userForm.valid && !this.isLoading) {
      this.authServ.login(this.userForm.value);
    } else {
      Object.keys(this.userForm.controls).forEach(field => {
        const control = this.userForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  // ---- toggle
  toggleLoading() {
    this.store.dispatch(new UI.StopLoading());
  }

  toggleAuth() {
    this.store.dispatch(new Auth.SetNotAuth());
  }

  toggleShow() {
    this.show = !this.show;
    if (this.show) {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }
}
