import { Action } from '@ngrx/store';
import { Transfer } from '../models/medic-actiuni.model';
import { Notificari } from '../models/nav-notificari.model';
import { DocCertProfCurent } from '../models/medic-doc.model';


export const TRANSFER_ADD = '[Alert] Transfer Add';
export const TRANSFER_DEL = '[Alert] Transfer Delete';
export const NOTIFICARI_ADD = '[Alert] Notificari Add';
export const CPC_ADD = '[Alert] CPC Add';
export const CPC_DEL = '[Alert] CPC Delete';

export class TransferAdd implements Action {
  readonly type = TRANSFER_ADD;

  constructor(public payload: Transfer[]) { }
}

export class TransferDelete implements Action {
  readonly type = TRANSFER_DEL;
}

export class CpcAdd implements Action {
  readonly type = CPC_ADD;

  constructor(public payload: DocCertProfCurent[]) { }
}

export class CpcDelete implements Action {
  readonly type = CPC_DEL;
}

export class NotificariAdd implements Action {
  readonly type = NOTIFICARI_ADD;

  constructor(public payload: Notificari[]) { }
}

export type AlertActions = TransferAdd | TransferDelete | NotificariAdd | CpcAdd | CpcDelete;
