import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  Route
} from '@angular/router';


import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private store: Store<fromRoot.State>, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const observable = this.store.select(fromRoot.getIsAuthenticated);
    observable.subscribe(isAuthenticated => {
      if (!isAuthenticated) {
        this.router.navigateByUrl('/autorizare');
      }
    });
    return observable;
  }
}

@Injectable()
export class AuthGuardIsAdmin implements CanActivate {

  isAdmin: boolean;

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const observable = this.store.select(fromRoot.getIsAdministrator);

    observable.subscribe(isAdmin => {
      if (!isAdmin) {
        this.router.navigateByUrl('/apps/dashboard');
      }
    });

    return observable;
  }
}

@Injectable()
export class AuthGuardIsMedic implements CanActivate {

  isMedicSelected: boolean;

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const observable = this.store.select(fromRoot.getIsMedicSelected);

    observable.subscribe(isSelected => {
      if (!isSelected) {
        this.router.navigateByUrl('/dashboard');
      }
    });

    return observable;
  }
}
