import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as UI from '../redux/ui.actions';
import * as Reg from '../redux/registre.actions';

import { ApiService } from './api.service';
import { SnackBarService } from './snackbar.service';
import {
    Registru,
    ActIdTip,
    MedicStatus,
    Judet,
    Tara,
    Facultate,
    FacultateDocTip,
    PpuTip,
    Ppu,
    PpuGrad,
    PpuDoc,
    Titlu,
} from '../models/registre.model';
import { tap, shareReplay } from 'rxjs/operators';
import { EmcTip } from '../models/registre.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class RegistreService {

    registru: Registru;

    constructor(
        private store: Store<fromRoot.State>,
        private apiService: ApiService,
        private snackBar: SnackBarService
    ) {
        this.store
            .select(fromRoot.getRegistre)
            .subscribe(e => this.registru = e);
    }

    private showError(error: HttpErrorResponse) {
        this.store.dispatch(new UI.StopLoading());

        if (error.error instanceof ErrorEvent) {
            this.snackBar.showSnackbar('Eroare Client: ' + error.error.message, 'snackbar-alert');
        } else {
            if (error.error.msg === undefined) {
                this.snackBar.showSnackbar(`Eroare Server : ${error.status}, ` + error.message, 'snackbar-alert');
            } else {
                this.snackBar.showSnackbar(`Eroare Server : ${error.status}, ` + error.error.msg, 'snackbar-alert');
            }
        }
    }


    getJudet() {
        this.store.dispatch(new UI.StartLoading());
        const answer = this.apiService.getJudeteList().pipe(
            tap(() => this.store.dispatch(new UI.StopLoading())),
            shareReplay()
        )
            .subscribe(
                (response) => {
                    this.store.dispatch(new Reg.JudeteAdd(response));
                },
                (error: HttpErrorResponse) => {
                    this.showError(error);
                }
            );
    }

    getRegistre() {
        this.store.dispatch(new UI.StartLoading());
        const answer = this.apiService.getRegistre().pipe(
            tap(() => this.store.dispatch(new UI.StopLoading())),
            shareReplay()
        )
            .subscribe(
                (response: Registru) => {
                    this.store.dispatch(new Reg.TariAdd(response.tara));
                    this.store.dispatch(new Reg.ActIdTipAdd(response.act_id_tip));
                    this.store.dispatch(new Reg.FacultateAdd(response.facultate));
                    this.store.dispatch(new Reg.FacDocTipAdd(response.facultate_doc_tip));
                    this.store.dispatch(new Reg.PpuAdd(response.ppu));
                    this.store.dispatch(new Reg.PpuTipAdd(response.ppu_tip));
                    this.store.dispatch(new Reg.PpuGradAdd(response.ppu_grad));
                    this.store.dispatch(new Reg.PpuDocAdd(response.ppu_doc));
                    this.store.dispatch(new Reg.PpuGrupAdd(response.ppu_grup));
                    this.store.dispatch(new Reg.PpuDocAdd(response.ppu_doc));
                    this.store.dispatch(new Reg.TitluAdd(response.titlu));
                    this.store.dispatch(new Reg.AdresaTipAdd(response.adresa_tip));
                    this.store.dispatch(new Reg.AsiguratorAdd(response.asigurator));
                    this.store.dispatch(new Reg.CmjAdd(response.cmj));
                    this.store.dispatch(new Reg.MedicStatusAdd(response.medic_status));
                    this.store.dispatch(new Reg.DocTipAdd(response.doc_tip));
                    this.store.dispatch(new Reg.DocStatusAdd(response.doc_status));
                    this.store.dispatch(new Reg.JudeteAdd(response.judet));
                    this.store.dispatch(new Reg.MedicActiuniTipAdd(response.actiuni_tip));
                    this.store.dispatch(new Reg.CmjServiciiAdd(response.cmj_servicii));
                    this.store.dispatch(new Reg.EmcTipAdd(response.emc_tip));
                },
                (error: HttpErrorResponse) => {
                    this.showError(error);
                }
            );
    }

    listaJudete() {
        return this.registru.judet;
    }

    listaJudeteFaraDiverse() {
        const filterOut = [141, 142, 143, 144, 145, 146, 160, 161];
        return this.registru.judet.filter(function (item) {
            return filterOut.indexOf(item.id) === -1;
        });
    }

    filterJudete(nume: string): Judet[] {
        return this.registru.judet
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    get displayJudet() {
        return val => {
            if (val && this.registru.judet) {
                return this.registru.judet.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    listaTara() {
        return this.registru.tara;
    }

    filterTara(nume: string): Tara[] {
        return this.registru.tara
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    get displayTara() {
        return val => {
            if (val && this.registru.tara) {
                return this.registru.tara.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    listaIdCardTip() {
        return this.registru.act_id_tip;
    }

    filterIdCardTip(nume: string): ActIdTip[] {
        return this.registru.act_id_tip
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    get displayIdCardTip() {
        return val => {
            if (val && this.registru.act_id_tip) {
                return this.registru.act_id_tip.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    listaFacultate() {
        return this.registru.facultate;
    }

    filterFacultate(nume: string): Facultate[] {
        return this.registru.facultate
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.facultate)
            );
    }

    get displayFacultate() {
        return val => {
            if (val && this.registru.facultate) {
                return this.registru.facultate.find(item => item.id === val).facultate;
            } else {
                return null;
            }
        };
    }

    listaFacDocTip() {
        return this.registru.facultate_doc_tip;
    }

    filterFacDocTip(nume: string): FacultateDocTip[] {
        return this.registru.facultate_doc_tip
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    get displayFacDocTip() {
        return val => {
            if (val && this.registru.facultate_doc_tip) {
                return this.registru.facultate_doc_tip.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    listaPpuTip() {
        return this.registru.ppu_tip;
    }

    filterPpuTip(nume: string): PpuTip[] {
        return this.registru.ppu_tip
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    get displayPpuTip() {
        return val => {
            if (val && this.registru.ppu_tip) {
                return this.registru.ppu_tip.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    listaPpu() {
        return this.registru.ppu;
    }

    filterPpu(nume: string): Ppu[] {
        return this.registru.ppu
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    filterPpuNumeTip(nume: string, tip: number): Ppu[] {
        if (tip === 2) {
            return this.registru.ppu
                .filter(item => item.tip_id === tip)
                .filter(option =>
                    new RegExp(`${nume}`, 'gi').test(option.nume)
                );
        } else {
            return this.registru.ppu
                .filter(item => item.tip_id !== 2)
                .filter(option =>
                    new RegExp(`${nume}`, 'gi').test(option.nume)
                );
        }
    }

    get displayPpu() {
        return val => {
            if (val && this.registru.ppu) {
                return this.registru.ppu.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    listaPpuGrad() {
        return this.registru.ppu_grad.filter(item => item.id !== 9);
    }

    filterPpuGrad(nume: string): PpuGrad[] {
        return this.registru.ppu_grad
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    get displayPpuGrad() {
        return val => {
            if (val && this.registru.ppu_grad) {
                return this.registru.ppu_grad.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    listaPpuDocTip() {
        return this.registru.ppu_doc;
    }

    filterPpuDocTip(nume: string): PpuDoc[] {
        return this.registru.ppu_doc
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    get displayPpuDocTip() {
        return val => {
            if (val && this.registru.ppu_doc) {
                return this.registru.ppu_doc.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    listaTitluNume() {
        return this.registru.titlu;
    }

    filterTitluNume(nume: string): Titlu[] {
        return this.registru.titlu
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    get displayTitluNume() {
        return val => {
            if (val && this.registru.titlu) {
                return this.registru.titlu.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    get displayTitluTip() {
        return val => {
            if (val && this.registru.titlu) {
                return this.registru.titlu.find(item => item.id === val).tip;
            } else {
                return null;
            }
        };
    }

    listaAdresaTip() {
        return this.registru.adresa_tip;
    }

    filterAdresaTip(nume: string) {
        return this.registru.adresa_tip
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    get displayAdresaTip() {
        return val => {
            if (val && this.registru.adresa_tip) {
                return this.registru.adresa_tip.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    listaDocTip() {
        return this.registru.doc_tip;
    }

    filterDocTip(nume: string) {
        return this.registru.doc_tip
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    listaDocTipCert() {
        return this.registru.doc_tip.filter(
            ({ id }) => environment.listaDocTipCertificat.includes(id)
        );
    }

    filterDocTipCertificat(nume: string) {
        return this.registru.doc_tip
            .filter(
                ({ id }) => environment.listaDocTipCertificat.includes(id)
            )
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    get displayDocTip() {
        return val => {
            if (val && this.registru.doc_tip) {
                return this.registru.doc_tip.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    listaDocStatus() {
        return this.registru.doc_status;
    }

    filterDocStatus(nume: string) {
        return this.registru.doc_status
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    get displayDocStatus() {
        return val => {
            if (val && this.registru.doc_status) {
                return this.registru.doc_status.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    listaAsigurator() {
        return this.registru.asigurator;
    }

    filterAsigurator(nume: string) {
        return this.registru.asigurator
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    get displayAsigurator() {
        return val => {
            if (val && this.registru.asigurator) {
                return this.registru.asigurator.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    listaMedicStatus() {
        return this.registru.medic_status;
    }

    filterMedicStatus(nume: string) {
        return this.registru.medic_status
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    get displayMedicStatus() {
        return val => {
            if (val && this.registru.medic_status) {
                return this.registru.medic_status.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    get iconMedicStatus() {
        return val => {
            return this.registru.medic_status.find(item => item.id === val).icon;
        };
    }


    listaMedicActiuniTip() {
        return this.registru.actiuni_tip;
    }

    filterMedicActiuniTip(nume: string) {
        return this.registru.actiuni_tip
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    get displayMedicActiuniTip() {
        return val => {
            if (val && this.registru.actiuni_tip) {
                return this.registru.actiuni_tip.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    listaCmjServicii(userJudet?: number) {
        if (userJudet) {
            if (userJudet !== 161) {
                return this.registru.cmj_servicii
                    .filter(item => item.activ === true)
                    .filter(
                        item => (item.judet_id === userJudet || item.judet_id === 161)
                    )
                    .sort((a,b) => (b.judet_id - a.judet_id))
                    .slice();
            }
        }
        return this.registru.cmj_servicii.filter(item => item.activ === true).slice();
    }

    filterCmjServicii(nume: string, judet_id: number) {
        if (judet_id !== 160) {
            return this.registru.cmj_servicii
                .filter(item => item.activ === true)
                .filter(item => item.judet_id === judet_id)
                .filter(option =>
                    new RegExp(`${nume}`, 'gi').test(option.nume)
                ).slice();
        } else {
            return this.registru.cmj_servicii
                .filter(item => item.activ === true)
                .filter(option =>
                    new RegExp(`${nume}`, 'gi').test(option.nume)
                ).slice();
        }
    }

    get displayCmjServicii() {
        return val => {
            if (val && this.registru.cmj_servicii) {
                return this.registru.cmj_servicii.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    listaEmcTip() {
        return this.registru.emc_tip;
    }

    filterEmcTip(nume: string) {
        return this.registru.emc_tip
            .filter(option =>
                new RegExp(`${nume}`, 'gi').test(option.nume)
            );
    }

    get displayEmcTip() {
        return val => {
            if (val && this.registru.emc_tip) {
                return this.registru.emc_tip.find(item => item.id === val).nume;
            } else {
                return null;
            }
        };
    }

    getEmcValabilitate(emcId): number {
        if (typeof (emcId) === 'number') {
            const selectedItem = this.registru.emc_tip.filter(
                item => item.id === emcId
            );
            return selectedItem[0].valabilitate;
        } else {
            return 0;
        }
    }

}
