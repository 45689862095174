import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, AfterViewInit, OnChanges } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import { Medic } from '../../shared/models/medic.model';
import { User } from '../../shared/models/user.model';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss']
})
export class NavHeaderComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() sidenav;

  prod = environment.production;

  isFullscreen = false;
  showLoading: boolean;

  // sectionName: string;
  sectionName = 'ceva';
  appSectionName$: Subscription;

  medic$: Subscription;
  medic: Medic;

  isMedicSelected$: Subscription;
  isMedicSelected: boolean;

  currentUser: User;
  currentUser$: Subscription;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private store: Store<fromRoot.State>
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.showLoading = true;
      } else if (event instanceof NavigationEnd) {
        this.showLoading = false;
      }
    });
  }

  ngOnInit() {
    this.appSectionName$ = this.store.select(fromRoot.appSectionName).subscribe(data => {
      this.sectionName = data;
      this.cd.detectChanges();
    });

    this.medic$ = this.store.select(fromRoot.medicDate).subscribe(medicId => {
      this.medic = medicId;
      this.cd.detectChanges();
    });

    this.isMedicSelected$ = this.store.select(fromRoot.getIsMedicSelected).subscribe(isMedicSelected => {
      this.isMedicSelected = isMedicSelected;
      this.cd.detectChanges();
    });

    this.currentUser$ = this.store.select(fromRoot.getUserDetails).subscribe(user => {
      this.currentUser = user;
      this.cd.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnChanges() {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.appSectionName$.unsubscribe();
    this.medic$.unsubscribe();
    this.isMedicSelected$.unsubscribe();
    this.currentUser$.unsubscribe();
  }
}
