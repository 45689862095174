<!-- <mat-toolbar class="app-header mat-elevation-z3" color="primary" fxLayoutGap="10px"> -->
<mat-toolbar color="primary" [className]="prod ? 'app-header mat-elevation-z3 prod' : 'app-header mat-elevation-z3 devel'" fxLayoutGap="10px">

    <button mat-icon-button (click)="sidenav.opened = !sidenav.opened">
        <mat-icon>menu</mat-icon>
    </button>
    <button mat-button [routerLink]="'/'" *ngIf="currentUser"><span *ngIf="!prod">SITE TEST - </span> <span>C.M. {{
        currentUser.judet_id | judet}}</span></button>
    <app-nav-medic *ngIf="medic && isMedicSelected" [medic]="medic"></app-nav-medic>
    <span fxFlex></span>
    <app-nav-notificari></app-nav-notificari>
    <app-nav-user></app-nav-user>

</mat-toolbar>