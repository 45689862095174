import { Action } from '@ngrx/store';
import { User } from '../models/user.model';

export const USER_LIST_ADD = '[Admin] User list add';
export const USER_LIST_DEL = '[Admin] User list delete';

export class UserListAdd implements Action {
    readonly type = USER_LIST_ADD;

    constructor(public payload: User[]) { }
}

export class UserListDel implements Action {
    readonly type = USER_LIST_DEL;
}

export type AdminActions = UserListAdd | UserListDel;

