import { Injectable } from '@angular/core';
import * as _moment from 'moment';
// import { default as _rollupMoment, Moment } from 'moment';
// import { MAT_DATE_FORMATS } from '@angular/material';
// const moment = _rollupMoment || _moment;

// export const MY_FORMATS = {
//     parse: {
//         dateInput: 'DD.MM.YYYY'
//     },
//     display: {
//         dateInput: 'DD.MM.YYYY',
//         monthYearLabel: 'YYYY MMMM',
//         dateA11yLabel: 'LL',
//         monthYearA11yLabel: 'YYYY MMMM'
//     }
// };

@Injectable()
export class MomentService {

    constructor() { }

    getIsoDate(dateObj) {
        const date = new Date(dateObj);
        return date.toISOString();
    }

    getDateString(momentValue) {
        const year = momentValue._d.getFullYear();
        const month = momentValue._d.getMonth() + 1;
        const day = momentValue._d.getDate();
        const dateString = year + '-' + month + '-' + day;
        return dateString;
    }

}
