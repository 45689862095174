import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as UI from '../redux/ui.actions';
import * as Auth from '../redux/auth.actions';

import { ApiService } from './api.service';
import { RegistreService } from './registre.service';
import { SnackBarService } from './snackbar.service';

import { User, UserLogin, ApiAuthResponse, JWTToken } from '../models/user.model';
import { AlertsService } from './alerts.service';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable()
export class AuthService {

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
    private apiService: ApiService,
    private regService: RegistreService,
    private snackBar: SnackBarService,
    private alertService: AlertsService
  ) { }

  private showError(error: HttpErrorResponse) {
    this.store.dispatch(new UI.StopLoading());

    if (error.error instanceof ErrorEvent) {
      this.snackBar.showSnackbar('Eroare Client: ' + error.error.message, 'snackbar-alert');
    } else {
      if (error.error.msg === undefined) {
        this.snackBar.showSnackbar(`Eroare Server : ${error.status}, ` + error.message, 'snackbar-alert');
      } else {
        this.snackBar.showSnackbar(`Eroare Server : ${error.status}, ` + error.error.msg, 'snackbar-alert');
      }
    }
  }

  login(user: UserLogin) {
    this.store.dispatch(new UI.StartLoading());
    const answer = this.apiService.callLogin(user).pipe(
      tap(() => this.store.dispatch(new UI.StopLoading())),
      shareReplay()
    )
      .subscribe(
        (response: ApiAuthResponse) => {
          this.snackBar.showSnackbar('Autentificare cu succes');
          const userData = this.getUser(response);
          this.store.dispatch(new UI.UserAdd(userData));
          if (userData.role === 0) {
            this.store.dispatch(new Auth.SetAdmin);
          }
          this.store.dispatch(new Auth.SetAuth());
          this.store.dispatch(new Auth.SetAccessToken(response.access_token));
          this.store.dispatch(new Auth.SetRefreshToken(response.refresh_token));
          this.router.navigate(['/']);
          this.regService.getRegistre();
          this.alertService.getAlert('transfer');
        },
        (error: HttpErrorResponse) => {
          this.showError(error);
        }
      );
  }

  refreshToken(tokenRefresh: string) {
    // this.store.dispatch(new UI.StartLoading());
    return this.apiService.callRefreshToken(tokenRefresh).pipe(
      tap((response: ApiAuthResponse) => {
        // this.store.dispatch(new UI.StopLoading());
        this.store.dispatch(new Auth.SetAccessToken(response.access_token));
      }),
      shareReplay()
    );
  }

  getUser(response: ApiAuthResponse) {
    const tokenExpanded: JWTToken = this.jwt_decode(response.access_token);
    const user: User = tokenExpanded.claims;
    return user;
  }

  logout() {
    this.store.dispatch(new Auth.SetNotAuth());
    this.store.dispatch(new UI.SidenavHide());
    this.store.dispatch(new UI.UserRemove());
    // this.store.dispatch(new UI.StopLoading());
    this.snackBar.showSnackbar('Autentificarea a expirat');
    this.router.navigateByUrl('/autorizare');
  }


  private jwt_decode(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
}


