<div class="app-error404-container" fxLayout="colunm" fxLayoutAlign="center center">
  <div class="app-error404-main mat-elevation-z12">
    <mat-card align="center">
      <mat-card-content>
          <h1 class="section-title">{{'error404'}}</h1>
          <p class="explanation">Această pagină nu există, mergi la ....</p>
          <p class="explanation"><a mat-raised-button color="primary" routerLink="/">Pagina principală</a></p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
