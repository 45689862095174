import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppConfig } from '../config/app.config';
import { UserLogin } from '../models/user.model';
import { isThisTypeNode } from 'typescript';

@Injectable()
export class ApiService {
    private apiUrl = AppConfig.endpoints.apiURL;
    private apiAuthUrl = AppConfig.endpoints.apiAuthURL;

    constructor(private http: HttpClient) {}

    callLogin(user: UserLogin) {
        var formData: any = new FormData();
        formData.append('username', user.email);
        formData.append('password', user.password);

        return this.http.post(`${this.apiAuthUrl}`, formData);
    }

    callRefreshToken(refreshToken: string) {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer ' + refreshToken);
        return this.http.get(`${this.apiAuthUrl}/refresh`, { headers: headers });
    }

    getUserLista(): Observable<any> {
        return this.http.get(`${this.apiUrl}/admin/users`, {});
    }

    callRegistre(): Observable<any> {
        return this.http.get(`${this.apiUrl}/registre`);
    }

    getJudeteList(): Observable<any> {
        return this.http.get(`${this.apiUrl}/registre/judet`);
    }

    getRegistre(): Observable<any> {
        return this.http.get(`${this.apiUrl}/registre`);
    }

    cautareMedic(data: string, endpoint: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/dashboard/search/${endpoint}?search_value=${data}`);
    }

    getMedicData(idMedic): Observable<any> {
        return this.http.get(`${this.apiUrl}/medic/${idMedic}`);
    }

    checkCNP(data): Observable<any> {
        // const item = { 'cnp': data };
        return this.http.get(`${this.apiUrl}/medic/check/cnp?value=${data}`);
    }

    checkEmail(data): Observable<any> {
        // const item = { 'email': data };
        return this.http.get(`${this.apiUrl}/medic/check/email?value=${data}`);
    }

    addMedicNew(data): Observable<any> {
        return this.http.post(`${this.apiUrl}/medic`, data);
    }

    addMedicItem(data, endpoint, medicId): Observable<any> {
        return this.http.post(`${this.apiUrl}/medic/${medicId}/${endpoint}`, data);
    }

    addMedicItem2(data, endpoint, medicId): Observable<any> {
        if (data) {
            return this.http.post(`${this.apiUrl}/medic/${medicId}/${endpoint}`, data);
        } else {
            return this.http.post(`${this.apiUrl}/${endpoint}`, null);
        }
    }

    editMedicItem(data, endpoint, medicId, itemId): Observable<any> {
        if (itemId) {
            return this.http.patch(`${this.apiUrl}/medic/${medicId}/${endpoint}/${itemId}`, data);
        } else {
            return this.http.patch(`${this.apiUrl}/medic/${medicId}/${endpoint}`, data);
        }
    }

    // TODO: de modificat get, gen, del si print pt doc generic

    getCertDraft(medicId): Observable<any> {
        return this.http.get(`${this.apiUrl}/medic/${medicId}/doc/certificat/draft`);
    }

    genCert(medicId): Observable<any> {
        return this.http.post(`${this.apiUrl}/medic/${medicId}/doc/certificat`, null);
    }

    delCert(medicId, certId, motiv): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: motiv,
        };
        return this.http.delete(`${this.apiUrl}/medic/${medicId}/doc/certificat/${certId}`, options);
    }

    printCert(medicId, certId): Observable<any> {
        return this.http.get(`${this.apiUrl}/medic/${medicId}/doc/certificat/${certId}/pdf`, { responseType: 'blob' });
    }

    getAvizareDraft(medicId): Observable<any> {
        return this.http.get(`${this.apiUrl}/medic/${medicId}/doc/avizare/draft`);
    }

    genAvizare(medicId, avizare): Observable<any> {
        return this.http.post(`${this.apiUrl}/medic/${medicId}/doc/avizare`, avizare);
    }

    delAvizare(medicId, avizId, motiv): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: motiv,
        };
        return this.http.delete(`${this.apiUrl}/medic/${medicId}/doc/avizare/${avizId}`, options);
    }

    printAvizare(medicId, avizId): Observable<any> {
        return this.http.get(`${this.apiUrl}/medic/${medicId}/doc/avizare/${avizId}/pdf`, { responseType: 'blob' });
    }

    startTransfer(data): Observable<any> {
        return this.http.post(`${this.apiUrl}/medic/${data.medicId}/actiuni/transfer`, data.data);
    }

    startTransferV2(data): Observable<any> {
        return this.http.post(`${this.apiUrl}/medic/${data.medicId}/actiuni/transfer`, data.formValues);
    }

    stopTransfer(medicId, transferId, data): Observable<any> {
        return this.http.put(`${this.apiUrl}/medic/${medicId}/actiuni/transfer/${transferId}`, data);
    }

    transferV2(data): Observable<any> {
        if (data.pas === 'delete') {
            const options = {
                body: {
                    comentariu: data.formValues.comentariu,
                },
            };
            return this.http.delete(
                `${this.apiUrl}/medic/${data.medicId}/actiuni/transfer/${data.transferId}`,
                options
            );
        } else {
            if (data.isNew) {
                return this.http.post(
                    `${this.apiUrl}/medic/${data.medicId}/actiuni/transfer/${data.transferId}/${data.pas}`,
                    data.formValues
                );
            } else {
                return this.http.patch(
                    `${this.apiUrl}/medic/${data.medicId}/actiuni/transfer/${data.transferId}/${data.pas}`,
                    data.formValues
                );
            }
        }
    }

    getTransfer(endpoint): Observable<any> {
        return this.http.get(`${this.apiUrl}/cmj/${endpoint}`);
    }

    getCpc(endpoint, full): Observable<any> {
        return this.http.get(`${this.apiUrl}/cmj/${endpoint}/${full}`);
    }

    changeStatus(medicId, data): Observable<any> {
        return this.http.post(`${this.apiUrl}/medic/${medicId}/actiuni/status`, data);
    }

    getItem(endpoint): Observable<any> {
        return this.http.get(`${this.apiUrl}/${endpoint}`);
    }

    postItem(data, endpoint): Observable<any> {
        if (data) {
            return this.http.post(`${this.apiUrl}/${endpoint}`, data);
        } else {
            return this.http.post(`${this.apiUrl}/${endpoint}`, null);
        }
    }

    patchItem(data, endpoint): Observable<any> {
        if (data) {
            return this.http.patch(`${this.apiUrl}/${endpoint}`, data);
        } else {
            return this.http.patch(`${this.apiUrl}/${endpoint}`, null);
        }
    }

    putItem(data, endpoint): Observable<any> {
        return this.http.put(`${this.apiUrl}/${endpoint}`, data);
    }

    delItem(params, endpoint): Observable<any> {
        if (params) {
            return this.http.delete(`${this.apiUrl}/${endpoint}`, {
                // params: { mid: data.mid }
                params: params,
            });
        } else {
            return this.http.delete(`${this.apiUrl}/${endpoint}`);
        }
    }

    delItem2(data, endpoint): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: data,
        };
        return this.http.delete(`${this.apiUrl}/${endpoint}`, options);
    }

    printItem(endpoint): Observable<any> {
        return this.http.get(`${this.apiUrl}/${endpoint}`, { responseType: 'blob' });
    }

    downloadRaportXslx(tip): Observable<any> {
        return this.http.get(`${this.apiUrl}/cmj/raport/xlsx/medic/${tip}`, { responseType: 'blob' });
    }

    apiGetReport(url, params): Observable<any> {
        return this.http.get(`${this.apiUrl}/cmj/raport/${url}`, { params: params, responseType: 'blob' });
    }

    getFile(url, params): Observable<any> {
        if (params) {
            return this.http.get(`${this.apiUrl}/${url}`, { params: params, responseType: 'blob' });
        } else {
            return this.http.get(`${this.apiUrl}/${url}`, { responseType: 'blob' });
        }
    }

    postRaportXslx(tip, data): Observable<any> {
        return this.http.post(`${this.apiUrl}/cmj/raport/xlsx/${tip}`, data, { responseType: 'blob' });
    }

    getMedicEmcTotal(medicId, date): Observable<any> {
        // let getParams = {};
        let q_date = '';
        if (date) {
            if (typeof date.value === 'string') {
                q_date = date.value.split('T')[0];
            } else if (typeof date === 'string') {
                q_date = date.split('T')[0];
            } else {
                if (date._isAMomentObject) {
                    q_date = date._d.toISOString().split('T')[0];
                } else {
                    q_date = date.toIsoString().split('T')[0];
                }
            }
        } else {
            const today = new Date();
            q_date = today.toISOString().split('T')[0];
        }

        return this.http.get(`${this.apiUrl}/medic/${medicId}/emc/total/${q_date}`);
    }

    getMedicEmcExcel(medicId): Observable<any> {
        return this.http.get(`${this.apiUrl}/medic/${medicId}/emc/export`, { responseType: 'blob' });
    }

    resetRegMedDBFull(): Observable<any> {
        return this.http.get(`${this.apiUrl}/admin/medic/regmed-all`);
    }
}
