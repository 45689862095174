import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


import { NavigationRoutingModule } from './navigation-routing.module';
import { NavigationComponent } from './navigation.component';
import { SharedModule } from '../shared/modules/shared.module';
import { NavBrandComponent } from './nav-brand/nav-brand.component';
import { NavFooterComponent } from './nav-footer/nav-footer.component';

import { NavHeaderModule } from './nav-header/nav-header.module';
import { NavLeftModule } from './nav-left/nav-left.module';
import { NavLoadingComponent } from './nav-loading/nav-loading.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    NavigationRoutingModule,
    NavHeaderModule,
    NavLeftModule
  ],
  declarations: [
    NavigationComponent,
    NavBrandComponent,
    NavFooterComponent,
    NavLoadingComponent
  ]
})
export class NavigationModule { }
