
import { AuthActions, SET_AUTH, SET_NOT_AUTH, SET_ACCESS_TOKEN, REMOVE_ACCESS_TOKEN, SET_REFRESH_TOKEN, SET_ADMIN } from './auth.actions';

export interface State {
  isAuthenticated: boolean;
  isAdmin: boolean;
  accessToken: string;
  refreshToken: string;
}

const initialState: State = {
  isAuthenticated: false,
  isAdmin: false,
  accessToken: '',
  refreshToken: ''
};

export function authReducer(state = initialState, action: AuthActions) {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        isAuthenticated: true
      };
    case SET_NOT_AUTH:
      return {
        isAuthenticated: false,
        isAdmin: false,
        refreshToken: '',
        accessToken: ''
      };
    case SET_ADMIN:
      return {
        ...state,
        isAdmin: true,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload
      };
    case REMOVE_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: '',
      };
    case SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload
      };
    default:
      return state;
  }
}

export const getIsAuthenticated = (state: State) => state.isAuthenticated;
export const getIsAdmin = (state: State) => state.isAdmin;
export const tokenAccess = (state: State) => state.accessToken;
export const tokenRefresh = (state: State) => state.refreshToken;

