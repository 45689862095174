import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromRoot from '../app.reducer';
import * as UI from '../shared/redux/ui.actions';
import { RegistruCMJ, RegistruCMR } from '../shared/models/cautare.model';
import { RegistreService } from '../shared/services/registre.service';

import { Page } from '../shared/models/page.model';
import { AppSectionAdd } from '../shared/redux/ui.actions';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnDestroy, OnInit {
    showDebug$: Observable<boolean>;
    listaCMJ: RegistruCMJ;
    listaCMJSub: Subscription;
    listaCMR: RegistruCMR;
    listaCMRSub: Subscription;

    constructor(
        private store: Store<fromRoot.State>,
        private regService: RegistreService
    ) {
        this.showDebug$ = this.store.select(fromRoot.getDebugShow);
        this.listaCMJSub = this.listaCMJSub = this.store.select(fromRoot.getCautareMedicCMJ).subscribe(lista => {
            this.listaCMJ = lista;
        });
        this.listaCMRSub = this.store.select(fromRoot.getCautareMedicCMR).subscribe(lista => {
            this.listaCMR = lista;
        });
        this.store.dispatch(new UI.AppSectionAdd('dashboard'));
        this.store.dispatch(new UI.AppSectionNameAdd('Pagină Principală'));
        // this.regService.getRegistre();
    }

    ngOnInit() {
        this.store.dispatch(new UI.MedicIdRemove);
    }

    ngOnDestroy() {
        this.listaCMJSub.unsubscribe();
        this.listaCMRSub.unsubscribe();
    }

}
