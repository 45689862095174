import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { AppConfig } from '../config/app.config';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as UI from '../redux/ui.actions';

@Injectable()
export class SnackBarService {
  duration = AppConfig.snackBarDuration;
  // TODO: define custom type 'top';
  verticalPosition = AppConfig.snackBarVerticalPosition;

  constructor(
    private snackBar: MatSnackBar,
    private store: Store<fromRoot.State>,
  ) { }

  showSnackbar(msg: string, className?: string, action?: string) {
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.duration = this.duration;
    config.verticalPosition = 'top';
    if (className) {
      config.panelClass = className;
    } else {
      config.panelClass = 'snackbar-default';
    }
    this.snackBar.open(msg, action, config);
    return;
  }

  showError(error: HttpErrorResponse) {
    this.store.dispatch(new UI.StopLoading());
    if (error.error instanceof ErrorEvent) {
      this.showSnackbar('Eroare Client: ' + error.error.message, 'snackbar-alert');
    } else {
      if (error.error.detail === undefined && error.error.message === undefined) {
        this.showSnackbar(`Eroare Server : ${error.status}, ` + error.message, 'snackbar-alert');
      } else {
        if (typeof (error.error.detail) === 'string') {
          this.showSnackbar(`Eroare Server : ${error.status}, ` + error.error.detail, 'snackbar-alert');
        } else {
          this.showSnackbar(this.getErrorDetail(error.error.detail), 'snackbar-alert');
        }
      }
    }
  }

  getErrorDetail(errorList) {
    let message = '';
    for (const item of errorList) {
      message = message + item.loc + ': ' + item.msg + '; ';
    }
    return message;
  }
}

