<div class="toolbar-notification-container">
    <button mat-icon-button (click)="toggleDropdown()" [ngClass]="[cssPrefix+'-btn']" [class.open]="isOpen">
      <mat-icon [matBadge]="notificariLista?.length" matBadgeColor="warn" [matBadgeHidden]="notificariLista?.length === 0">notifications_none</mat-icon>
    </button>
  
    <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
      <div class="card">
        <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="title">
            <div class="name">Notificări</div>
            <div class="extra">Aveți {{ notificariLista?.length }} notificări</div>
          </div>
          <button type="button" mat-icon-button>
            <mat-icon class="icon">settings</mat-icon>
          </button>
        </div>
        <div *ngIf="notificariLista.length !== 0; then thenBlock else elseBlock;"></div>
        <div class="footer" fxLayout="row" fxLayoutAlign="center center">
          <div class="action" (click)="refresh()" >Actualizează lista notificărilor</div>
        </div>
      </div>
    </div>
  </div>
  
  
  <ng-template #thenBlock>
    <perfect-scrollbar class="content">
      <ng-container *ngFor="let notification of notificariLista; last as isLast">
        <div class="notice" fxLayout="row" fxLayoutAlign="start center" mat-ripple>
          <mat-icon class="icon" *ngIf="notification.body.type === 'transfer'">check_circle</mat-icon>
          <mat-icon class="icon" *ngIf="notification.body.type !== 'transfer'">notifications</mat-icon>
          <div class="title" fxLayout="column" (click)="itemAction($event, notification)">
            <div class="name">{{ notification.body.subject }}</div>
            <div class="time">{{ notification.msg_from }}</div>
          </div>
          <span fxFlex></span>
          <button type="button" mat-icon-button (click)="delete($event, notification)">
            <mat-icon class="close">delete</mat-icon>
          </button>
        </div>
        <div class="divider" *ngIf="!isLast"></div>
      </ng-container>
    </perfect-scrollbar>
  </ng-template>
  
  <ng-template #elseBlock>
    <div class="no" fxLayout="row" fxLayoutAlign="center center">Nici o notificare</div>
  </ng-template>  