import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SnackBarService } from '../services/snackbar.service';
import { ApiService } from '../services/api.service';
import { FormValidatorsService } from '../validators/form-validators.service';
import { RegistreService } from '../services/registre.service';
import { MomentService } from '../services/moment.service';
import { TransferService } from '../services/transfer.service';

import { JudetPipe } from '../pipes/judet.pipe';
import { MedicStatusPipe } from '../pipes/status.pipe';
import { TaraPipe } from '../pipes/tara.pipe';
import { ActIdentitateTipPipe } from '../pipes/act-identitate-tip.pipe';
import { FacNumePipe } from '../pipes/fac-nume.pipe';
import { FacDocTipPipe } from '../pipes/fac-doc-tip.pipe';
import { PpuPipe } from '../pipes/ppu.pipe';
import { PpuTipPipe } from '../pipes/ppu-tip.pipe';
import { PpuGradPipe } from '../pipes/ppu-grad.pipe';
import { PpuDocTipPipe } from '../pipes/ppu-doc-tip.pipe';
import { TitluNumePipe } from '../pipes/titlu-nume.pipe';
import { AdresaTipPipe } from '../pipes/adresa-tip.pipe';
import { DocStatusPipe } from '../pipes/doc-status.pipe';
import { MedicActiuniTipPipe } from '../pipes/medic-status-tip.pipe';
import { TransferV2StepperComponent } from '../components/transfer-v2/transfer-v2-stepper/transfer-v2-stepper.component';
import { TransferV2CerereComponent } from '../components/transfer-v2/transfer-v2-cerere/transfer-v2-cerere.component';
import { CmjServiciiPipe } from '../pipes/cmj-servicii.pipe';
import { EmcTipPipe } from '../pipes/emc-tip.pipe';
import { FormCalendarComponent } from '../components/form-calendar/form-calendar.component';
import { FormCalendarPerioadaComponent } from '../components/form-calendar-perioada/form-calendar-perioada.component';
import { FormCertTipComponent } from '../components/form-cert-tip/form-cert-tip.component';
import { FormCpcScopComponent } from '../components/form-cpc-scop/form-cpc-scop.component';
import { FormDocStatusComponent } from '../components/form-doc-status/form-doc-status.component';
import { FormFreeTextComponent } from '../components/form-free-text/form-free-text.component';
import { FormUpDownFileComponent } from '../components/form-up-dow-file/form-up-down-file.component';
import { UploadDownloadFileService } from '../services/up-down-file.service';
import { FormAsiguratorComponent } from '../components/form-asigurator/form-asigurator.component';

@NgModule({
    declarations: [
        JudetPipe,
        MedicStatusPipe,
        TaraPipe,
        ActIdentitateTipPipe,
        FacNumePipe,
        FacDocTipPipe,
        PpuPipe,
        PpuTipPipe,
        PpuGradPipe,
        PpuDocTipPipe,
        TitluNumePipe,
        AdresaTipPipe,
        DocStatusPipe,
        CmjServiciiPipe,
        MedicActiuniTipPipe,
        EmcTipPipe,
        TransferV2StepperComponent,
        TransferV2CerereComponent,
        FormCalendarComponent,
        FormCalendarPerioadaComponent,
        FormCertTipComponent,
        FormCpcScopComponent,
        FormDocStatusComponent,
        FormFreeTextComponent,
        FormUpDownFileComponent,
        FormAsiguratorComponent,
    ],
    imports: [
        MaterialModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule
    ],
    exports: [
        MaterialModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        JudetPipe,
        MedicStatusPipe,
        TaraPipe,
        ActIdentitateTipPipe,
        FacNumePipe,
        FacDocTipPipe,
        PpuPipe,
        PpuTipPipe,
        PpuGradPipe,
        PpuDocTipPipe,
        TitluNumePipe,
        AdresaTipPipe,
        CmjServiciiPipe,
        DocStatusPipe,
        MedicActiuniTipPipe,
        EmcTipPipe,
        TransferV2StepperComponent,
        TransferV2CerereComponent,
        FormCalendarComponent,
        FormCalendarPerioadaComponent,
        FormCertTipComponent,
        FormDocStatusComponent,
        FormCpcScopComponent,
        FormFreeTextComponent,
        FormUpDownFileComponent,
        FormAsiguratorComponent,
    ],
    providers: [
        SnackBarService,
        ApiService,
        RegistreService,
        FormValidatorsService,
        MomentService,
        TransferService,
        UploadDownloadFileService
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                SnackBarService,
                ApiService,
                RegistreService,
                FormValidatorsService,
                MomentService,
                TransferService,
                UploadDownloadFileService
            ]
        };
    }
}
