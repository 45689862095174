import { Pipe, PipeTransform } from '@angular/core';
import { MedicStatus } from '../models/registre.model';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import { take } from 'rxjs/operators';

@Pipe({
  name: 'medic_status'
})
export class MedicStatusPipe implements PipeTransform {
  numeItem: string;
  listaItem: MedicStatus[];

  constructor(
    private store: Store<fromRoot.State>
  ) {
    this.store.select(fromRoot.getRegMedicStatus).pipe(
      take(1)
    )
        .subscribe(res => {
            this.listaItem = res;
        });
  }

  transform(value: number): string {
    const item = this.listaItem.filter(itemFilter => itemFilter.id === value);
    if (item.length > 0) {
        return item[0].nume;
    } else {
        return value.toString();
    }
  }

}
