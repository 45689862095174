import { Component, HostListener, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../../app.reducer';

import { User } from '../../../shared/models/user.model';
import { RegistreService } from '../../../shared/services/registre.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../core/dialog/dialog.component';
import { DialogDynamicComponent } from '../../../core/dialog/dialog-dynamic/dialog-dynamic.component';
import { gitVersions } from '../../../../environments/versions';


@Component({
    selector: 'app-nav-user',
    templateUrl: './nav-user.component.html',
    styleUrls: ['./nav-user.component.scss']
})
export class NavUserComponent implements OnDestroy {

    isOpen = false;
    // currentUser = null;
    currentUser: User;
    currentUser$: Subscription;

    @HostListener('document:click', ['$event', '$event.target'])
    onclick(event: MouseEvent, targetElement: HTMLElement) {
        if (!targetElement) {
            return;
        }
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.isOpen = false;
        }
    }


    constructor(
        private store: Store<fromRoot.State>,
        private _elementRef: ElementRef,
        private route: Router,
        // private auth: AuthService
        private regServ: RegistreService,
        public dialog: MatDialog
    ) {
        this.currentUser$ = this.store.select(fromRoot.getUserDetails).subscribe(user => {
            this.currentUser = user;
        });
    }

    ngOnDestroy() {
        this.currentUser$.unsubscribe();
    }

    toggleDropDown() {
        this.isOpen = !this.isOpen;
    }

    logout() {
        console.log('Hit logout');
        // this.auth.signOut().then(() => {
        //   this.router.navigate(['/sigin']);
        // });
    }

    refresh() {
        this.regServ.getRegistre();
    }

    get_anyDesk() {
        window.open('https://anydesk.com/en', '_blank');
    }

    showVersion() {
        const dialogRef = this.dialog.open(
            DialogComponent, {
            height: '400px',
            width: '600px',
            closeOnNavigation: false,
            disableClose: true,
            data: {
                component: DialogDynamicComponent,
                appVersion: gitVersions,
            }
        }
        );
    }

}
