import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { SnackBarService } from './snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';

import { saveAs } from 'file-saver';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as UI from '../../shared/redux/ui.actions';
import * as Cautare from '../../shared/redux/cautare.actions';

import { AlertsService } from './alerts.service';
import { shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable()
export class ActiuniService {

  constructor(
    private store: Store<fromRoot.State>,
    private apiService: ApiService,
    private snackBar: SnackBarService,
    private alertServ: AlertsService,
    private router: Router
  ) { }

  private showError(error: HttpErrorResponse) {
    this.store.dispatch(new UI.StopLoading());

    if (error.error instanceof ErrorEvent) {
      this.snackBar.showSnackbar('Eroare Client: ' + error.error.message, 'snackbar-alert');
    } else {
      if (error.error.msg === undefined && error.error.message === undefined) {
        this.snackBar.showSnackbar(`Eroare Server : ${error.status}, ` + error.message, 'snackbar-alert');
      } else {
        if (error.error.msg) {
          this.snackBar.showSnackbar(`Eroare Server : ${error.status}, ` + error.error.msg, 'snackbar-alert');
        } else {
          this.snackBar.showSnackbar(error.error.message, 'snackbar-alert');
        }
      }
    }
  }


  medicStartTransfer(medicId, data) {
    this.apiService.startTransfer(data).pipe(
      shareReplay()
    )
      .subscribe(
        (response) => {
          this.snackBar.showSnackbar(response.msg);
          // this.medicServ.getMedicData(medicId);
        },
        (error: HttpErrorResponse) => {
          this.showError(error);
        }
      );
  }

  medicFinishTransfer(medicId, itemId, data) {
    this.apiService.stopTransfer(medicId, itemId, data).pipe(
      shareReplay()
    )
      .subscribe(
        (response) => {
          this.snackBar.showSnackbar(response.msg);
          this.store.dispatch(new Cautare.ListaCMJDelete());
          this.alertServ.getAlert('transfer');
          this.router.navigateByUrl('/dashboard');
        },
        (error: HttpErrorResponse) => {
          this.showError(error);
        }
      );
  }

  medicCpcValideazaDoc(endpoint) {
    this.apiService.patchItem(null, endpoint).pipe(
      shareReplay()
    )
      .subscribe(
        (response) => {
          this.snackBar.showSnackbar("Documentul a fost validat.");
          this.alertServ.getAlert('cpc');
        },
        (error: HttpErrorResponse) => {
          this.showError(error);
        }
      );
  }

  medicCpcPrinteazaDoc(endpoint) {
    this.apiService.printItem(endpoint).pipe(
      shareReplay()
    )
      .subscribe(
        (response) => {
          const blob = new Blob([(<any>response)], { type: 'application/pdf' });
          const urlA = window.URL.createObjectURL(blob);
          window.open(urlA);
          // saveAs(blob, 'cert_prof_curent.pdf');
        },
        (error: HttpErrorResponse) => {
          this.showError(error);
        }
      );
  }

}
