import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ApiService } from '../services/api.service';

@Injectable()
export class FormValidatorsService {

    constructor(
        private apiServ: ApiService
    ) { }

    checkCNP(control: UntypedFormGroup): { [s: string]: boolean } {
        // TODO: de verificat daca mai exista in baza de date!!
        const testValCNP = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
        let splitCNP = [];
        splitCNP = String(control.value).split('');
        let sum = 0;
        if (splitCNP.length !== 13) {
            return { cnpLengthIsInvalid: true };
        } else {
            for (let i = 0; i < testValCNP.length; i++) {
                sum = sum + splitCNP[i] * testValCNP[i];
            }
            if (sum % 11 === 10) {
                sum = 1;
            }
            if (sum % 11 !== +splitCNP[12]) {
                return { cnpCtrlSumInvalid: true };
            } else {
                return null;
            }
        }
    }

    isInteger(control: UntypedFormGroup): { [s: string]: boolean } {
        if (control.value) {
            const value = control.value;
            if (Number.isInteger(value)) {
                return null;
            } else {
                return { isNotInteger: true };
            }
        } else {
            return null;
        }
    }

    isBoolean(control: UntypedFormGroup): { [s: string]: boolean } {
        if (control.value) {
            if (typeof control.value === 'boolean') {
                return null;
            } else {
                return { isNotBoolean: true };
            }
        } else {
            return null;
        }
    }

    isBooleanTrue(control: UntypedFormGroup): { [s: string]: boolean } {
        if (control.value) {
            if (typeof control.value === 'boolean' && control.value === true) {
                return null;
            } else {
                return { isNotBooleanTrue: true };
            }
        } else {
            return null;
        }
    }

}
