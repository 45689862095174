import { Action } from '@ngrx/store';

import { Medic, MedicEmcTotal } from '../models/medic.model';
import { DocCertificat, DocAvizare, DocCertProfCurent } from '../models/medic-doc.model';
import { Transfer } from '../models/medic-actiuni.model';

export const SET_MEDIC_ACTIVE_MODULE = '[Medic] Set active module';
export const SET_MEDIC_DATA = '[Medic] Set date';
export const UNSET_MEDIC_DATA = '[Medic] Unset date';
export const SET_MEDIC_CERT_DRAFT = '[Medic] Set date draft certificat';
export const UNSET_MEDIC_CERT_DRAFT = '[Medic] Unset date draft certificat';
export const SET_MEDIC_AVIZ_DRAFT = '[Medic] Set date draft avizare';
export const UNSET_MEDIC_AVIZ_DRAFT = '[Medic] Unset date draft avizare';
export const SET_MEDIC_CERT_PROF_CURENT_DRAFT = '[Medic] Set  date draft cert prof curent';
export const UNSET_MEDIC_CERT_PROF_CURENT_DRAFT = '[Medic] Unset  date draft cert prof curent';
export const SET_MEDIC_TRANSFER = '[Medic] Set medic Transfer';
export const UNSET_MEDIC_TRANSFER = '[Medic] Unset medic Transfer';
export const SET_MEDIC_EMC_TOTAL = '[Medic] Set medic total puncte EMC';
export const UNSET_MEDIC_EMC_TOTAL = '[Medic] Unset medic total puncte EMC';

export class SetMedicActiveModule implements Action {
    readonly type = SET_MEDIC_ACTIVE_MODULE;

    constructor(public payload: string) { }
}

export class SetMedicData implements Action {
    readonly type = SET_MEDIC_DATA;

    constructor(public payload: Medic) { }
}

export class UnsetMedicData implements Action {
    readonly type = UNSET_MEDIC_DATA;
}

export class SetMedicCertDraft implements Action {
    readonly type = SET_MEDIC_CERT_DRAFT;

    constructor(public payload: DocCertificat) { }
}

export class UnSetMedicCertDraft implements Action {
    readonly type = UNSET_MEDIC_CERT_DRAFT;
}

export class SetMedicAvizDraft implements Action {
    readonly type = SET_MEDIC_AVIZ_DRAFT;

    constructor(public payload: DocAvizare) { }
}

export class UnSetMedicAvizDraft implements Action {
    readonly type = UNSET_MEDIC_AVIZ_DRAFT;
}

export class SetMedicCertProfCurentDraft implements Action {
    readonly type = SET_MEDIC_CERT_PROF_CURENT_DRAFT;

    constructor(public payload: DocCertProfCurent) { }
}

export class UnSetMedicCertProfCurentDraft implements Action {
    readonly type = UNSET_MEDIC_CERT_PROF_CURENT_DRAFT;
}

export class SetMedicTransfer implements Action {
    readonly type = SET_MEDIC_TRANSFER;

    constructor(public payload: Transfer) { }
}

export class UnSetMedicTransfer implements Action {
    readonly type = UNSET_MEDIC_TRANSFER;
}

export class SetMedicEmcTotal implements Action {
    readonly type = SET_MEDIC_EMC_TOTAL;

    constructor(public payload: MedicEmcTotal) { }
}

export class UnSetMedicEmcTotal implements Action {
    readonly type = UNSET_MEDIC_EMC_TOTAL;
}

export type MedicActions = SetMedicActiveModule | SetMedicData | UnsetMedicData |
    SetMedicCertDraft | UnSetMedicCertDraft | SetMedicAvizDraft | UnSetMedicAvizDraft |
    SetMedicCertProfCurentDraft | UnSetMedicCertProfCurentDraft | SetMedicTransfer | UnSetMedicTransfer |
    SetMedicEmcTotal | UnSetMedicEmcTotal;
